import React, { Component } from 'react';
import TopVeranstaltungen from './TopVeranstaltungen';
import TopVersorgungspartner from './TopVersorgungspartner';
import HeadoutPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import './Styles/main-home.css';

class MainHome extends Component {
  render() {
    const { veranstaltungenData } = this.props;
    return (
      <>
        <Media />
        <TopVeranstaltungen type="Veranstaltung" veranstaltungenData={veranstaltungenData.filter(d => d.type === "Veranstaltung")} />
        <Collections collectionsData={veranstaltungenData.filter(d => d.type === "Referent")} />
        <HeadoutPicks pickedData={veranstaltungenData.filter( v =>  v.type === "Produkt" )} headline={'Produkte im Fokus'} />
        <TopVersorgungspartner type="Versorgungspartner" veranstaltungenData={veranstaltungenData.filter(d => d.type === "Versorgungspartner")} />

      </>
    );
  }
}

// Currently using this Data for Headout Top Picks

const pickedData = [
  {
    id: 1,
    currentPrice: 29,
    currency: '$',
    stars: 4.6,
    ratings: 681,
    city: 'Produkt 1',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',
  },
  {
    id: 2,
    currentPrice: 57.5,
    currency: '$',
    stars: 4.6,
    ratings: 564,
    cashback: 10,
    city: 'Produkt 2',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',
      
  },
  {
    id: 3,
    lastPrice: 79,
    currency: '$',
    discount: 49,
    city: 'Produkt 3',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',
      
  },
  {
    id: 4,
    currentPrice: 141,
    lastPrice: 146,
    currency: 'AED',
    discount: 16,
    stars: 4.6,
    ratings: 2588,
    cashback: 5,
    city: 'Produkt 4',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',

  },
  {
    id: 5,
    currentPrice: 196,
    lastPrice: 206,
    currency: 'AED',
    discount: 8,
    stars: 4.6,
    ratings: 1240,
    cashback: 5,
    city: 'Produkt 5',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',

  },
  {
    id: 6,
    currentPrice: 20,
    currency: '€',
    stars: 4.6,
    ratings: 437,
    city: 'Produkt 6',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',

  },
  {
    id: 7,
    currentPrice: 31,
    lastPrice: 45,
    currency: '€',
    discount: 31,
    stars: 4.6,
    ratings: 474,
    cashback: 10,
    city: 'Produkt 7',
    description: 'Hier ist eine ausführliche Beschreibung des Produkts...',
    url: '/pmp.png',
  }
];

// Collection Data

const collectionsData = [
  {
    id: 1,
    description: 'Referent Name 1',
    url: '/referent-1.jpg',
      
  },
  {
    id: 2,
    description: 'Referent Name 2',
    url: '/referent-2.jpg',
      
  },
  {
    id: 3,
    description: 'Referent Name 3',
    url: '/referent-3.jpg',
      
  },
  {
    id: 4,
    description: 'Referent Name 4',
    url: '/referent-4.jpg',
      
  },
  {
    id: 5,
    description: 'Referent Name 5',
    url: '/referent-5.jpg',
      
  },
  {
    id: 6,
    description: 'Referent Name 6',
    url: '/referent-6.jpg',
      
  },
  {
    id: 7,
    description: 'Referent Name 7',
    url: '/referent-7.jpg',
      
  },
  {
    id: 8,
    description: 'Referent Name 8',
    url: '/referent-8.jpg',
      
  },
  {
    id: 9,
    description: 'Referent Name 9',
    url: '/referent-9.jpg',
      
  },
  {
    id: 10,
    description: 'Referent Name 10',
    url: '/referent-10.jpg',

  },
  {
    id: 11,
    description: 'Referent Name 11',
    url: '/referent-11.jpg',

  },
  {
    id: 12,
    description: 'Referent Name 12 ',
    url: '/referent-12.jpg',

  },
];

export default MainHome;
