import React, { useEffect, useState, useContext, useRef } from "react";
import { Divider, List, Typography, Button, Select, Card, Col, Form, Checkbox, Input, message, Radio, Row, Spin, DatePicker,Upload, Rate, Switch } from "antd";
import MapPicker from "./MapPicker";
import { PlusOutlined, MinusCircleOutlined , FrownOutlined, SafetyCertificateOutlined, MehOutlined, SmileOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout'
import dayjs from 'dayjs';
import localObject from '../germanLocale.json';
import { API, API_BASE, STRAPI_API } from "../constant";
import { getToken } from "../helpers";
import { AuthContext } from "../context/AuthContext";
import { PicturesWall } from "./PicturesWall";
import styled, { createGlobalStyle } from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import { useAuthContext } from "../context/AuthContext";

const { Option } = Select;
// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
};
const onOk = (value) => {
  console.log('onOk: ', value);
};

//const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return (e || {}).fileList;
};

const VeranstaltungProfile = ({ type, id, veranstaltungenData, herstellerData, referentenData }) => {
const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");


const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};

const [fileList, setFileList] = useState(((veranstaltung || {}).Medien || {}).data || []);
  const [loading, setLoading] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState("");
  const [defaultValues, setDefaultValues] = useState({});
  const [form] = Form.useForm();
  const [placement, SetPlacement] = useState('');
  const [teilnehmerzahl, SetTeilnehmerzahl] = useState('');


    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
        }
    }, [form, defaultValues])

    useEffect(() => {
        const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};

        if (veranstaltung.Veranstaltungsort && veranstaltung.Veranstaltungsort.length === 2) setSelectedCoordinates(veranstaltung.Veranstaltungsort[0] + "," + veranstaltung.Veranstaltungsort[1]);
        const defaultDates = (veranstaltung.date || []).length === 2 ? [moment(veranstaltung.date[0], dateFormat), moment(veranstaltung.date[1], dateFormat)] : [];

        const defaultValues = {};
        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        defaultValues.referents = ((veranstaltung.referents || {}).data || []).map((r) => { return { "label": (r.attributes.Vorname + " " + r.attributes.Name), "value": r.id }});
        defaultValues.herstellers = ((veranstaltung.herstellers || {}).data || []).map((r) => { return { "label": (r.attributes.Name), "value": r.id }});

        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [veranstaltungenData]);

  const handleSelectCoordinates = (coordinates) => {
    setSelectedCoordinates(coordinates);
  };

  const updateMarkerBasedOnStadt = () => {
      // get values of inputs Stadt and PLZ
      const stadt = form.getFieldValue("Stadt");
      const plz = form.getFieldValue("PLZ");

      fetch(`https://geocode.maps.co/search?q=${plz},${stadt},Germany&api_key=65d4b23015fec727948775azl6467b9`)
          .then((response) => response.json())
          .then((data) => {
          // update the marker based on the response
          if (((data || {})[0] || {}).lat) {
              setSelectedCoordinates(data[0].lat + "," + data[0].lon);
              message.success("Koordinaten gefunden und Karte angepasst!");
          } else {
              message.error("Keine Koordinaten gefunden...");
          }
          });

      }


  const handleVeranstaltungUpdate = async (data) => {
    setLoading(true);
    try {
      const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id);
      let response;

        console.debug("daddta user", user);

        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (!dataToPost["Bild"] || (veranstaltung && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];
        dataToPost.Medien = (fileList || []).map( media => {
                    if (((media.response) || []) [0]) {
                        return media.response[0];
                    } else {
                        return media;
                    }
                 });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];

dataToPost.herstellers = (dataToPost.herstellers || []).map(h => typeof h === 'number' ? h : h.value);
dataToPost.referents = (dataToPost.referents || []).map(h => typeof h === 'number' ? h : h.value);


         dataToPost.createdby = (user || {}).id;

         delete dataToPost.dragger;
//         delete dataToPost.Vergabestelle;
//          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        if ((dataToPost.date || [])[0]) dataToPost.Startzeit = dataToPost.date[0].$d; //`${dataToPost.date[0]._d.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })} ${dataToPost.date[0]._d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        if ((dataToPost.date || [])[1]) dataToPost.Endzeit = dataToPost.date[1].$d; //`${dataToPost.date[1]._d.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })} ${dataToPost.date[1]._d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;

        if ((dataToPost.dateN || [])[0]) dataToPost.Startzeit = dataToPost.dateN[0].$d; //`${dataToPost.date[0]._d.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })} ${dataToPost.date[0]._d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        if ((dataToPost.dateN || [])[1]) dataToPost.Endzeit = dataToPost.dateN[1].$d; //`${dataToPost.date[1]._d.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })} ${dataToPost.date[1]._d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;

        delete dataToPost.date;
        delete dataToPost.dateN;

        dataToPost.Veranstaltungsort = JSON.parse("[" + selectedCoordinates + "]");

      if (veranstaltung) {
        response = await fetch(`${API}/veranstaltungs/${veranstaltung.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      } else {
        response = await fetch(`${API}/veranstaltungs`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      }
      const responseData = await response.json();

      if (responseData.error) {
        alert("Es konnte nicht gespeichert werden, bitte schicken Sie unserem Support die genauen Daten die gespeichert werden sollten um das Problem zu lösen oder verändern Sie die Eingabe oder verändern Sie die Eingabe.");
      } else {
        // Update logic based on your application's requirements
        message.success("Daten erfolgreich gespeichert!");
        window.location.replace("/admin/meine-inserate");
      }
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  console.debug("selectedCoordinates", selectedCoordinates );


  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };

  const teilnehmerzahlChange = (e: RadioChangeEvent) => {
    SetTeilnehmerzahl(e.target.value);
  };

    const fields = [
         { key: '0', name: ['Fortbildungspunkte', 0], fieldKey: '0' },
         { key: '1', name: ['Fortbildungspunkte', 1], fieldKey: '1' },
         { key: '2', name: ['Fortbildungspunkte', 2], fieldKey: '2' },
       ];

    const isNotAllowed = (typeof veranstaltung.id === 'undefined') && (selectedSubTitle === "Veranstalter: Classic" && veranstaltungenData.filter((f,i) => f.createdby === (user || {}).id).length > 2);
    console.debug("ttt",veranstaltung, (defaultValues || {}).Startzeit, (defaultValues || {}).Endzeit, [moment((defaultValues || {}).Startzeit, dateFormat), moment((defaultValues || {}).Endzeit, dateFormat)]);

  return (
    <>
    { !isNotAllowed ? <>
      <Card className="veranstaltung_profile_card">
      <PageHeader style={{display: 'none'}}
      backIcon={<SafetyCertificateOutlined style={{ color: "#fff", fontSize: 32 }} />}
          className="veranstaltung-zertifizierung-header"
          onBack={() => null}
          title={<span style={{ color: "#fff" }}> Veranstaltung zertifizieren</span>}
          subTitle={<span style={{ color: "#fff" }}> Falls Sie Ihre Veranstaltung zertifizieren möchten, können Sie den Antrag hier stellen...</span>}
        />
          <Form
            form={form}
            layout="vertical"
            initialValues={defaultValues}
            onFinish={handleVeranstaltungUpdate}
          >
            <Row gutter={[16, 16]}>
              <Col span={8}>
              <Form.Item
                label="Name der Veranstaltung"
                name="Veranstaltungsname"
                rules={[
                  {
                    required: false,
                    message: "Label ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
                          <Form.Item
                            label="Name des Veranstalters"
                            name="Veranstaltername"
                            rules={[
                              {
                                required: false,
                                message: "Label ist ein Pflichtfeld!",
                                type: "string",
                              },
                            ]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                        </Col>
                <Col span={6}>

                      {((defaultValues || {}).Startzeit && (defaultValues || {}).Endzeit) && (defaultValues || {}).Endzeit ? <Form.Item
                         label="Datum"
                         name="date"
                       ><RangePicker
                        locale={localObject}
                        defaultValue={ [moment((defaultValues || {}).Startzeit, dateFormat), moment( (defaultValues || {}).Endzeit, dateFormat)] }

                        showTime={{
                          format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChange}
                        onOk={onOk}
                      /></Form.Item> : <Form.Item
                                               label="Datum"
                                               name="dateN"
                                             ><RangePicker
                                                   locale={localObject}
                                                   showTime={{
                                                     format: 'HH:mm',
                                                   }}
                                                   format="YYYY-MM-DD HH:mm"
                                                   onChange={onChange}
                                                   onOk={onOk}
                                                 /></Form.Item>}

                </Col>
                    {(((veranstaltung.Bild || {}).data || {}).attributes) && <Col span={12}><img className={"leafImage leafImage--veranstaltung"} src={API_BASE + veranstaltung.Bild.data.attributes.url}  /> </Col> }
                    <Col span={(((veranstaltung.Bild || {}).data || {}).attributes) ? 8 : 24}>
                       <Form.Item label="Bild hochladen" name="Bild">
                            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                              <Upload.Dragger name="files"
                              crossOrigin={"use-credentials"}
                              data={{
                                ref: "api::veranstaltung.veranstaltung",
//                                refId: "42142444",
                                field: "Bild",
                              }} action={`${API}/upload`} headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Bild oder Video in diesen Bereich</p>
                                <p className="ant-upload-hint">Bilder im Format .png, .jpeg, .jpg werden unterstützt - Videos im Format .mp4 oder .webm</p>
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                      </Col>

                      <Divider />
                      { selectedSubTitle === "Veranstalter: Premium" && <Form.Item label="Medien" name="Medien (Erlaubt sind sowohl PDF, als auch Bild-, Video- und Audioformate)">
                          <PicturesWall fileList={fileList} setFileList={setFileList} />
                        </Form.Item> }
                      <Col span={24}>
                                      <Form.Item
                                        label="Beschreibung"
                                        name="Beschreibung"
                                        rules={[
                                          {
                                            required: false,
                                            message: "Beschreibung ist ein Pflichtfeld!",
                                            type: "string",
                                          },
                                        ]}
                                      >
                                        <ReactQuill theme="snow" />
                                      </Form.Item>
                    </Col>
                     <Col span={24}>
                                              <Form.Item
                                                value={teilnehmerzahl}
                                                label="Erwartete Teilnehmerzahl"
                                                name="ErwarteteTeilnehmerzahl"
                                                rules={[
                                                ]}
                                              >
                                                <Radio.Group value={teilnehmerzahl} onChange={teilnehmerzahlChange}>
                                                        <Radio.Button value="z0-20">0-20</Radio.Button>
                                                        <Radio.Button value="z21-50">21-50</Radio.Button>
                                                        <Radio.Button value="z51-100">51-100</Radio.Button>
                                                        <Radio.Button value="z101-150">101-150</Radio.Button>
                                                        <Radio.Button value="z151-300">151-300</Radio.Button>
                                                        <Radio.Button value="z301-500">301-500</Radio.Button>
                                                        <Radio.Button value="züber 150">über 500</Radio.Button>
                                                      </Radio.Group>
                                              </Form.Item>
                                            </Col>
                      <Col span={9}>
                        <Form.Item label="Anbieter">
                          <Input.Group compact>
                            <Form.Item
                              name={'Anbieter'}
                              noStyle
                            >
                              <Select style={{ paddingRight: '2em' }} placeholder="Anbieter auswählen">
                                <Option value="ICW e.V.">ICW e.V.</Option>
                                <Option value="DGfW e.V.">DGfW e.V.</Option>
                                <Option value="RbP e.V.">RbP e.V.</Option>
                                <Option value="Ärztekammer">Ärztekammer</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={'Anbieternummer'}
                              noStyle
                            >
                              <Input style={{ width: '50%', height: '34px' }} placeholder="Anbieternummer" />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                          <Form.Item
                            label="Ansprechpartner"
                            name="Ansprechpartner"
                            rules={[
                              {
                                required: false,
                                message: "Ansprechpartner ist ein Pflichtfeld!",
                                type: "string",
                              },
                            ]}
                          >
                            <Input type="string" placeholder="Ansprechpartner " />
                          </Form.Item>
                        </Col>
                      <Col span={8}>
                        <Form.Item
                          value={placement}
                          label="Teilnehmergebühr"
                          name="Teilnehmergebuehr"
                          rules={[
                          ]}
                        >
                          <Radio.Group value={placement} onChange={placementChange}>
                                  <Radio.Button value="z0-50">0-50 €</Radio.Button>
                                  <Radio.Button value="z51-100">51-100 €</Radio.Button>
                                  <Radio.Button value="z101-150">101-150 €</Radio.Button>
                                  <Radio.Button value="züber 150">über 150€</Radio.Button>
                                </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
<Form.List
  style={{ display: "block" }}
  className={"formList"}
  label="Fortbildungspunkte"
  name="Fortbildungspunkte"
  rules={[]}
  fieldKey="Fortbildungspunkte"
>
  {(fields, { add, remove }) => (
    <div className="flexHack">
      {fields.map((field, index) => (
        <List.Item key={field.key}>
          <Row style={{ flex: '0 0 100%;' }} gutter={[24, 24]}>
            <Col span={8}>
              <Form.Item
                name={[field.name, 'Vergabestelle']}
                style={{ width: '100%' }}
              >
                  <Select style={{ paddingRight: '2em' }} placeholder="Vergabestelle auswählen">
                      <Option value="Initiative Chronische Wunde e.V. (ICW)">ICW e.V.</Option>
                      <Option value="Deutsche Gesellschaft für Wundheilung und Wundbehandlung e.V. (DGfW)">DGfW e.V.</Option>
                      <Option value="Akademie ZWM GmbH (ZWM)">Akademie ZWM GmbH (ZWM)</Option>
                      <Option value="Registrierung beruflich Pflegender GmbH (regbp)">Registrierung beruflich Pflegender GmbH (regbp)</Option>
                      <Option value="Ärztekammer">Ärztekammer</Option>
                      <Option value="Fachgesellschaft Stoma-Kontinenz-Wunde e.V. (FgSKW)">Fachgesellschaft Stoma-Kontinenz-Wunde e.V. (FgSKW)</Option>
                      <Option value="Verband Deutscher Podologen e.V. (VDP)">Verband Deutscher Podologen e.V. (VDP)</Option>
                      <Option value="Deutscher Verband für Podologie e.V. (ZFD)">Deutscher Verband für Podologie e.V. (ZFD)</Option>
                      <Option value="Verband der Diabetes-Beratungs- und Schulungsberufe in Deutschland (VDBD)">Verband der Diabetes-Beratungs- und Schulungsberufe in Deutschland (VDBD)</Option>
                    </Select>

                  </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name={[field.name, 'Punkte']}
                style={{ width: '100%' }}
              >
                  <Rate
                    count={10}
                    style={{ color: '#3545b3' }}
                    defaultValue={0}
                    character={({ index }: { index: number }) => index + 1}
                    name={[field.name, 'Punkte']}
                  />
              </Form.Item>
            </Col>
            <Col span={4}>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Col>
          </Row>
        </List.Item>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
          style={{ width: '100%' }}
          icon={<PlusOutlined />}
        >
          Fortbildungspunkte hinzufügen
        </Button>
      </Form.Item>
    </div>
  )}
</Form.List>
                      </Col>


              <Col span={14}>
                <Form.Item
                  label="Stadt"
                  name="Stadt"
                  rules={[
                    {
                      required: false,
                      message: "Stadt ist ein Pflichtfeld!",
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Stadt" />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label="PLZ"
                  name="PLZ"
                  rules={[
                  ]}
                >
                  <Input placeholder="PLZ" />
                </Form.Item>
              </Col>
            <Col span={24}>
              <Button ghost
                type="primary"
                size="large"
                onClick={updateMarkerBasedOnStadt}
              >
                Aktualisiere den Marker basierend auf der Stadt und Postleitzahl
              </Button>
          </Col>

              <Col span={24}>
              <div className={"ant-form-item"}>
              <div className={"ant-row ant-form-item-row"}>
              <div className={"ant-col ant-form-item-label"}>Veranstaltungsort wählen <i>(ziehen sie den Marker auf den genauen Veranstaltungsort)</i></div>
                    <MapPicker defaultMarkerCoordinates={selectedCoordinates ? JSON.parse("[" + selectedCoordinates + "]") : [
                     50.02254683363231,
                     10.919726205325217
                     ]} onSelectCoordinates={handleSelectCoordinates} />
                     </div>
                 </div>
              </Col>
              <Col span={20}>
                  <Form.Item
                    label="Themengebiete"
                    name="Themengebiete"
                    rules={[
                    ]}
                  >
                      <Checkbox.Group options={[
                       'Expertenstandard',
                       'Haut/ Hautpflege',
                       'Wundarten und -heilung',
                       'Wundbeurteilung/ Dokumentation',
                       'Recht',
                       'Dekubitus/Prophylaxe',
                       'Diabetisches Fußsyndrom/ Prophylaxe',
                       'Ulcus Cruris',
                       'Schmerz',
                       'Hygiene',
                       'Edukation',
                       'Palliativ/Tumorwunden'
                       ]} onChange={onChange} />
                  </Form.Item>
              </Col>

              <Divider orientation="left">Referenten</Divider>
              <Col span={22}>
                <Form.Item
                  label="Referenten auf PMP-Online"
                  name="referents"

                  rules={[
                  ]}
                >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Referenten auf PMP-Online"

                      options = {referentenData.map(r => ({ label: (r.Vorname + " " + r.Name), value: r.id }))}
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
              <Form.List
                style={{ display: "block" }}
                className={"formList"}
                label="Externe Referenten"
                name="ExterneReferenten"
                rules={[]}
                fieldKey="ExterneReferenten"
              >
                {(fields, { add, remove }) => (
                  <div className="flexHack">
                    {fields.map((field, index) => (
                      <List.Item key={field.key}>
                        <Row style={{ flex: '0 0 100%;' }} gutter={[24, 24]}>
                          <Col span={8}>
                            <Form.Item
                                label="Name des Referenten"
                                name={[field.name, 'Name']}
                              >
                                <Input placeholder="Name" />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Col>
                        </Row>
                      </List.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Externe Referenten hinzufügen
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
                                    </Col>




                                    <Divider orientation="left">Hersteller</Divider>
                                                  <Col span={22}>
                                                    <Form.Item
                                                      label="Hersteller auf PMP-Online"
                                                      name="herstellers"

                                                      rules={[
                                                      ]}
                                                    >
                                                        <Select
                                                          mode="multiple"
                                                          allowClear
                                                          style={{
                                                            width: '100%',
                                                          }}
                                                          placeholder="Hersteller auf PMP-Online"

                                                          options = {herstellerData.map(r => ({ label: (r.Herstellername), value: r.id }))}
                                                        />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={24}>
                                                  <Form.List
                                                    style={{ display: "block" }}
                                                    className={"formList"}
                                                    label="Externe Hersteller"
                                                    name="ExterneHersteller"
                                                    rules={[]}
                                                    fieldKey="ExterneHersteller"
                                                  >
                                                    {(fields, { add, remove }) => (
                                                      <div className="flexHack">
                                                        {fields.map((field, index) => (
                                                          <List.Item key={field.key}>
                                                            <Row style={{ flex: '0 0 100%;' }} gutter={[24, 24]}>
                                                              <Col span={8}>
                                                                <Form.Item
                                                                    label="Name des Hersteller"
                                                                    name={[field.name, 'Name']}
                                                                  >
                                                                    <Input placeholder="Name" />
                                                                </Form.Item>
                                                              </Col>
                                                              <Col span={4}>
                                                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                              </Col>
                                                            </Row>
                                                          </List.Item>
                                                        ))}
                                                        <Form.Item>
                                                          <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                          >
                                                            Externe Hersteller hinzufügen
                                                          </Button>
                                                        </Form.Item>
                                                      </div>
                                                    )}
                                                  </Form.List>
                                                                        </Col>
                                                                        <Divider />


            <Col span={11}>
              <Form.Item
                label="Externer Link"
                name="ExternerLink"
              >
                <Input placeholder="beispiel.de"  addonBefore="https://" />
              </Form.Item>
            </Col>

            <Col span={11}>
                          <Form.Item
                            label="Link zur Anmeldung"
                            name="Anmeldelink"
                          >
                            <Input placeholder="beispiel.de/anmeldung"  addonBefore="https://" />
                          </Form.Item>
                        </Col>
        <Divider />

            <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 800}}>Profil veröffentlichen</span>}
                name="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            { selectedSubTitle === "Veranstalter: Premium" && <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 400}}>Fokussiere Profil</span>}
                name="Fokusiere"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col> }
            </Row>
            <Button
              className="veranstaltung_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {loading ? (
                <>
                  <Spin size="small" /> Speichern...
                </>
              ) : (
                "Speichern"
              )}
            </Button>
          </Form>
        </Card>
    </> : <div style={{ textAlign: 'center', padding: '20px' }}><FrownOutlined style={{ fontSize: '32px', color: '#3545b3' }} /><br />Sie haben bereits Ihr Limit an Veranstaltungen erreicht, es können keine weiteren erstellen werden.</div> } </>
  );
};

export default VeranstaltungProfile;