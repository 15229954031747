import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import styled, { createGlobalStyle } from 'styled-components';
import ReferentProfile from "../ReferentProfile";
import HerstellerProfile from "../HerstellerProfile";
import VersorgungspartnerProfile from "../VersorgungspartnerProfile";
import ProduktProfile from "../ProduktProfile";
import VeranstaltungProfile from "../VeranstaltungProfile";


// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const InserierenLeaf = (props) => {
  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser, setUserAndPlan } = useAuthContext();

  const handleInserierenLeafUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${(user || {}).id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      message.success("Daten erfolgreich gespeichert!");
    } catch (error) {
      console.error(Error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  const formByType = {
    "Referent" : <ReferentProfile type={props.type} id={props.id} referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} />,
    "Veranstaltung" : <VeranstaltungProfile type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "Hersteller")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Veranstaltung")} />,
    "Hersteller" : <HerstellerProfile type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Hersteller")} />,
    "Versorgungspartner": <VersorgungspartnerProfile type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Versorgungspartner")} />,
  }

  return (
  <>
    <Card className="InserierenLeaf_page_card">
      {formByType[props.type] }
    </Card>
    </>
  );
};

export default InserierenLeaf;
