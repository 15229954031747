import React, { Component } from 'react';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

class Datenschutz extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <>
<div className="city-section-wrapper static-padding"><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Datenschutzerklärung
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    ><div className="c24 doc-content">
       <p className="c3">
         <span className="c4" />
       </p>
       <p className="c7">
         <span className="c4">
           Der Schutz Ihrer personenbezogenen Daten ist uns ein sehr wichtiges
           Anliegen. Die folgende Information klärt Sie über den Umgang mit Ihren
           Daten auf, welche wir durch die Nutzung der Webseite erfassen. Natürlich
           werden Ihre Daten nach der gesetzlichen Regelung zum Datenschutz
           verarbeitet. Unsere Erklärung zum Datenschutz bezieht sich nur auf diese
           Webseite und dessen Unterseiten. Leitet unsere Webseite Sie auf eine
           andere Domain weiter, so gilt dort die Datenschutzerklärung des
           Betreibers,{" "}
         </span>
         <span className="c4">
PMPonline,
Radolfstrasse 18,
96179 Rattelsdorf, Deutschland
</span>
         <span className="c4">
           . Bitte informieren Sie sich dort über den Umgang Ihrer personenbezogenen
           Daten.
         </span>
       </p>
       <p className="c3">
         <span className="c4" />
       </p>
       <ol className="c5 lst-kix_list_1-1 start" start={1}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c17">
               Erfassung allgemeiner Informationen beim Besuch unserer Website
             </span>
           </h2>
         </li>
       </ol>
       <ol className="c5 lst-kix_list_1-2 start" start={1}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Art und Zweck der Verarbeitung</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
           registrieren oder anderweitig Informationen übermitteln, werden
           automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
           (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
           Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre
           IP-Adresse und weitere technisch notwendige Daten.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Sie werden insbesondere zu folgenden Zwecken verarbeitet:
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           &nbsp; &nbsp; • Sicherstellung eines problemlosen Verbindungsaufbaus der
           Website,
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           &nbsp; &nbsp; • Sicherstellung einer reibungslosen Nutzung unserer
           Website,
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           &nbsp; &nbsp; • Auswertung der Systemsicherheit und -stabilität sowie
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           &nbsp; &nbsp; • zu weiteren administrativen Zwecken.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Ihre Daten werden von uns pseudonymisiert gespeichert, damit kein
           Rückschluss auf Ihre Person genommen werden kann. Der Webserver verwendet
           diese Daten ausschließlich, um diese gegebenenfalls statistisch
           auszuwerten, damit wir unseren Internetauftritt und die dahinterstehende
           Technik zu optimieren können.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={2}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Rechtsgrundlage</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
           unseres berechtigten Interesses an der Verbesserung der Stabilität und
           Funktionalität unserer Website.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={3}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Empfänger</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Empfänger der Daten sind ggf. technische Dienstleister, die für den
           Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
           werden.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={4}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Speicherdauer</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
           mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der
           Webseite dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
           beendet ist. Allerdings werden einige relevante Sitzungsdaten
           pseudonymisiert auch nach Verlassen der Webseite gespeichert und
           statistisch zur Optimierung der Internetpräsenz ausgewertet. Diese Daten
           werden nach dessen Auswertung oder spätestens bei der nächsten
           Serverwartung gelöscht.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={5}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">
               Bereitstellung vorgeschrieben oder erforderlich
             </span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
           gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch
           der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet.
           Zudem können einzelne Dienste und Services nicht verfügbar oder
           eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={2}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c17">Cookies</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Für das Einholen der Einwilligung zur Verarbeitung Ihrer personenbezogenen
           Daten durch externe Auftragsverarbeiter verwenden wir einen sogenannten
           Cookie-Banner. Dieser Einsatz ist essenziell und gesetzlich
           vorgeschrieben. Durch Ihre Zustimmung werden Ihre Einwilligungen in
           sogenannten Cookies (persistente Speicher im Internet-Browser) gespeichert
           und nach der angegebenen Speicherdauer automatisch gelöscht. &nbsp;
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={3}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c0">Art und Zweck der Verarbeitung</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Wie viele andere Webseiten verwenden wir auch so genannte "Cookies". Bei
           Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät
           (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere
           Webseite besuchen.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse, verwendeter
           Browser und Betriebssystem.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Cookies können nicht verwendet werden, um Programme zu starten oder Viren
           auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen
           Informationen können wir Ihnen die Navigation erleichtern und die korrekte
           Anzeige unserer Webseiten ermöglichen.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
           betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
           Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies
           jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte
           verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren,
           wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass
           einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
           wenn Sie die Verwendung von Cookies deaktiviert haben.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Neben den erforderlichen Cookies zum Betrieb der Seite können Sie noch
           "Statistiken"-Cookies erlauben. Diese Form erlaubt uns die Daten an
           Drittanbieter für Tracking und Analyse weiterzugeben.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={4}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c0">Rechtsgrundlage</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die Rechtsgrundlage der Cookie-Speicherung für den erforderlichen Betrieb
           ist Art.6 Abs. 1 lit. f EU-DSGVO unser berechtigtes Interesse an einem
           ordnungsgemäßen Betrieb.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={5}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c0">Speicherdauer und eingesetzte Cookies</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Soweit Sie uns durch Ihre Browsereinstellungen oder explizite Einwilligung
           die Verwendung von Cookies erlauben, können folgende Cookies auf unseren
           Webseiten zum Einsatz kommen:
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Soweit diese Cookies (auch) personenbezogene Daten betreffen können,
           informieren wir Sie darüber in den folgenden Abschnitten.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Die gesetzten Cookies haben unterschiedliche Laufzeit, welche sie im
           Detailmenü der Kategorie im Cookie-Banner wiederfinden und verlieren
           automatisch die Gültigkeit nach Ablauf der Zeit.
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Des Weiteren können Sie über Ihre Browsereinstellungen einzelne Cookies
           oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie
           Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
           Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers
           finden Sie die notwendigen Informationen unter den nachfolgenden Links:
         </span>
       </p>
       <ul className="c5 lst-kix_list_15-0 start">
         <li className="c7 c12 li-bullet-0">
           <span className="c4">Mozilla Firefox:&nbsp;</span>
           <span className="c1">
             <a
               className="c16"
               href="https://www.google.com/url?q=https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen&sa=D&source=editors&ust=1710784823141949&usg=AOvVaw2jZ1OBsPeyZbWeNSzEKbBK"
             >
               https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
             </a>
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">Internet Explorer:&nbsp;</span>
           <span className="c1">
             <a
               className="c16"
               href="https://www.google.com/url?q=https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies&sa=D&source=editors&ust=1710784823142539&usg=AOvVaw20kdGAscKDL0XNTYHEbOVS"
             >
               https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
             </a>
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">Google Chrome:&nbsp;</span>
           <span className="c1">
             <a
               className="c16"
               href="https://www.google.com/url?q=https://support.google.com/accounts/answer/61416?hl%3Dde&sa=D&source=editors&ust=1710784823142966&usg=AOvVaw3_EW4F7MSy6fa8Hi761dxR"
             >
               https://support.google.com/accounts/answer/61416?hl=de
             </a>
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">Opera:&nbsp;</span>
           <span className="c1">
             <a
               className="c16"
               href="https://www.google.com/url?q=https://www.opera.com/de/help&sa=D&source=editors&ust=1710784823143407&usg=AOvVaw3P6jcoFPn_1WMYbUhem_fz"
             >
               https://www.opera.com/de/help
             </a>
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">Safari:&nbsp;</span>
           <span className="c1">
             <a
               className="c16"
               href="https://www.google.com/url?q=https://support.apple.com/kb/PH17191?locale%3Dde_DE%26viewlocale%3Dde_DE&sa=D&source=editors&ust=1710784823143838&usg=AOvVaw0YfKuhHsmrwr7-yB-wRzZ-"
             >
               https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE
             </a>
           </span>
         </li>
       </ul>
       <p className="c3">
         <span className="c4" />
       </p>
       <ol className="c5 lst-kix_list_1-1" start={6}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c17">unpkg</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Wir verwenden den Dienst unpkg als Content Delivery Network. Die über
           unpkg eingebundenen Dateien sind quelloffen, können also jederzeit
           eingesehen und überprüft werden.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={6}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Rechtsgrundlage</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die Einbindung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
           dem berechtigten Interesse an einer Aufwertung unserer Website sowie einer
           technisch sicheren, wartungsfreien und effizienten Möglichkeit externe
           Bibliotheken und Frameworks einzubinden.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={7}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Empfänger</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Da unpkg den Hostinganbieter Cloudflare nutzt um die Daten
           bereitzustellen, kann es sein, dass die Anfragen, die an diese Server
           gesendet werden, zu Statistik- oder anderen Nutzungszwecken gespeichert
           werden.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={8}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Speicherdauer</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Die gesammelten Rohdaten werden nach eigenen Angaben innerhalb von 4
           Stunden, spätestens jedoch nach 3 Tagen wieder gelöscht.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={9}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Weitere Informationen</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Nähere Informationen zu unpkg sowie die Datenschutzerklärung von
           Cloudflare finden Sie unter unpkg.com sowie
           cloudflare.com/de-de/privacypolicy/.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={7}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c17">Cloudflare (Content Delivery Network)</span>
           </h2>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Auf unserer Website verwenden wir ein sog. Content Delivery Network
           ("CDN") des Technologiedienstleisters Cloudflare Inc., 101 Townsend St.
           San Francisco, CA 94107, USA ("Cloudflare"). Bei einem Content Delivery
           Network handelt es sich um einen Online-Dienst, mit dessen Hilfe
           insbesondere große Mediendateien (wie z.B. Grafiken, Seiteninhalte oder
           Skripte) durch ein Netz regional verteilter und über das Internet
           verbundener Server ausgeliefert werden. Der Einsatz des Content Delivery
           Network von Cloudflare hilft uns bei der Optimierung der
           Ladegeschwindigkeiten unserer Website.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={10}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Rechtsgrundlage</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. a DSGVO auf Basis der
           Einwilligung für die Verarbeitung personenbezogener Daten, wie sie bei der
           Erfassung durch Cloudflare vorkommen kann.
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Von unserer Seite besteht zudem ein berechtigtes Interesse, Cloudflare zu
           verwenden, um unseren Online-Service zu optimieren und sicherer zu machen.
           Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO
           (Berechtigte Interessen). Wir setzen Cloudflare gleichwohl nur ein, soweit
           Sie uns eine Einwilligung erteilt haben.
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Wir haben mit Cloudfare einen Auftragsverarbeitungsvertrag (Data
           Processing Addendum, einsehbar unter
           https://www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf)
           abgeschlossen, mit dem Cloudfare verpflichtet wird, die Daten unserer
           Seitenbesucher zu schützen und sie nicht an Dritte weiter zu geben. Für
           die Übermittlung von Daten aus der EU in die USA beruft sich Cloudfare
           hierbei auf sog. Standarddatenschutzklauseln der Europäischen Kommission,
           welche die Einhaltung des europäischen Datenschutzniveaus in den USA
           gewährleisten sollen.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={11}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c0 c6">Weitere Informationen</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c15">
           Weitere Informationen finden Sie in der Datenschutzerklärung von
           Cloudflare unter:{" "}
         </span>
         <span className="c14">
           <a
             className="c16"
             href="https://www.google.com/url?q=https://www.cloudflare.com/privacypolicy/&sa=D&source=editors&ust=1710784823146492&usg=AOvVaw2RboRM6OcQwskpeeWx2psd"
           >
             https://www.cloudflare.com/privacypolicy/
           </a>
         </span>
       </p>
       <p className="c2">
         <span className="c4" />
       </p>
       <ol className="c5 lst-kix_list_1-1" start={8}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c17">Stripe</span>
           </h2>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Wir bieten die Möglichkeit, den Zahlungsvorgang über den
           Zahlungsdienstleister Stripe, ℅ Legal Process, 510,Townsend St., San
           Francisco, CA 94103 (Stripe) abzuwickeln.{" "}
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={12}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Rechtsgrundlage</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Dies entspricht unserem berechtigten Interesse, eine effiziente und
           sichere Zahlungsmethode anzubieten (Art. 6 Abs. 1 lit. f DSGVO). In dem
           Zusammenhang geben wir folgende Daten an Stripe weiter, soweit es für die
           Vertragserfüllung erforderlich ist (Art. 6 Abs. 1 lit b. DSGVO).
         </span>
       </p>
       <p className="c11">
         <span className="c4">Name des Karteninhabers</span>
       </p>
       <ul className="c5 lst-kix_list_7-0 start">
         <li className="c10 li-bullet-0">
           <span className="c4">E-Mail-Adresse</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Kundennummer</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Bestellnummer</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Bankverbindung</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Kreditkartendaten</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Gültigkeitsdauer der Kreditkarte</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Prüfnummer der Kreditkarte (CVC)</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Datum und Uhrzeit der Transaktion</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Transaktionssumme</span>
         </li>
         <li className="c10 li-bullet-0">
           <span className="c4">Name des Anbieters</span>
         </li>
         <li className="c11 c12 li-bullet-0">
           <span className="c4">Ort</span>
         </li>
       </ul>
       <p className="c11">
         <span className="c15">
           Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder
           gesetzlich noch vertraglich vorgeschrieben. Ohne die Übermittlung Ihrer
           personenbezogenen Daten können wir eine Zahlung über Stripe nicht
           durchführen.{" "}
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Stripe nimmt bei Datenverarbeitungstätigkeiten eine Doppelrolle als
           Verantwortlicher und Auftragsverarbeiter ein. Als Verantwortlicher
           verwendet Stripe Ihre übermittelten Daten zur Erfüllung regulatorischer
           Verpflichtungen. Dies entspricht dem berechtigten Interesse Stripes (gem.
           Art. 6 Abs. 1 lit. f DSGVO) und dient der Vertragsdurchführung (gem. Art.
           6 Abs. 1 lit. b DSGVO). Wir haben auf diesen Prozess keinen Einfluss.
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Als Auftragsverarbeiter fungiert Stripe, um Transaktionen innerhalb der
           Zahlungsnetzwerke abschließen zu können. Im Rahmen des
           Auftragsverarbeitungsverhältnisses wird Stripe ausschließlich nach unserer
           Weisung tätig und wurde im Sinne des Art. 28 DSGVO vertraglich
           verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Stripe hat Compliance-Maßnahmen für internationale Datenübermittlungen
           umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Stripe
           personenbezogene Daten von natürlichen Personen in der EU verarbeitet.
           Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs).
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={13}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Weitere Informationen</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c15">
           Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten
           gegenüber Stripe finden Sie unter:{" "}
         </span>
         <span className="c14">
           <a
             className="c16"
             href="https://www.google.com/url?q=https://stripe.com/privacy-center/legal&sa=D&source=editors&ust=1710784823149131&usg=AOvVaw0XIBhoZnmBO4FD6geu-VW2"
           >
             https://stripe.com/privacy-center/legal
           </a>
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-1" start={9}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c17">OpenStreetMaps</span>
           </h2>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Wir nutzen Darstellung von Geo-Daten den Open-Source-Karten-Dienst
           „OpenStreetMaps“ (auch „OSM“ genannt) der Firma OpenStreetMap Foundation,
           132 Maney Hill Road, Sutton Coldfield, West Midlands, B72 1JU, United
           Kingdom. OSM dient dazu, eine interaktive Karte auf unserer Webseite
           anzubieten, die Ihnen zeigt, wie Sie uns finden und erreichen können.
           Dieser Dienst ermöglicht es uns, unsere Webseite ansprechend darzustellen,
           indem Kartenmaterial von einem externen Server geladen wird.{" "}
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Folgende Daten werden bei der Darstellung an die Server von OSM
           übertragen:{" "}
         </span>
       </p>
       <ul className="c5 lst-kix_list_10-0 start">
         <li className="c11 c12 li-bullet-0">
           <span className="c4">
             Diejenige unserer Internetseiten, die Sie besucht haben und die
             IP-Adresse Ihres Endgerätes.{" "}
           </span>
         </li>
       </ul>
       <ol className="c5 lst-kix_list_1-2 start" start={1}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Rechtsgrundlage</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c4">
           Die Rechtsgrundlage für die Verarbeitung Ihrer Daten in Bezug auf den
           Dienst „OSM“ ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung).{" "}
         </span>
       </p>
       <p className="c11">
         <span className="c4">
           Von unserer Seite besteht zudem ein berechtigtes Interesse, OSM zu
           verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende
           Rechtsgrundlage ist Art. 6 Abs 1 lit. f DSGVO (Berechtigte Interessen).
           Wir setzen OSM gleichwohl nur ein, soweit Sie uns eine Einwilligung
           erteilt haben.
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={2}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Weitere Informationen</span>
           </h3>
         </li>
       </ol>
       <p className="c11">
         <span className="c15">
           Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
           Datenschutzerklärung von OSM:{" "}
         </span>
         <span className="c14">
           <a
             className="c16"
             href="https://www.google.com/url?q=https://wiki.osmfoundation.org/wiki/Privacy_Policy&sa=D&source=editors&ust=1710784823150745&usg=AOvVaw26006Uy2eCMBohe76SI4Ie"
           >
             https://wiki.osmfoundation.org/wiki/Privacy_Policy
           </a>
         </span>
       </p>
       <p className="c2">
         <span className="c4" />
       </p>
       <p className="c2">
         <span className="c4" />
       </p>
       <ol className="c5 lst-kix_list_1-1" start={10}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c17">Verantwortliche Stelle</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
           EU-Datenschutzgrundverordnung (DSGVO), ist:
         </span>
       </p>
       <ol className="c5 lst-kix_list_1-2" start={3}>
         <li className="c8">
           <h3 style={{ display: "inline" }}>
             <span className="c6 c0">Verantwortlicher</span>
           </h3>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           PMPonline
           <br />
           Radolfstrasse 18
           <br />
           96179 Rattelsdorf
         </span>
       </p>
       <p className="c7">
        <span className="c4">Telefon: +49 176 45869050</span>

       </p>
       <ol className="c5 lst-kix_list_1-1" start={11}>
         <li className="c9">
           <h2 style={{ display: "inline" }}>
             <span className="c6 c21">Ihre Betroffenenrechte</span>
           </h2>
         </li>
       </ol>
       <p className="c7">
         <span className="c4">
           Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
           Sie jederzeit folgende Rechte ausüben:
         </span>
       </p>
       <ul className="c5 lst-kix_list_16-0 start">
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
             (Art. 15 DSGVO),
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
             gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
             und
           </span>
         </li>
         <li className="c7 c12 li-bullet-0">
           <span className="c4">
             Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
             haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
           </span>
         </li>
       </ul>
       <p className="c7">
         <span className="c4">
           Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
           mit Wirkung für die Zukunft widerrufen. (Art. 7 Abs. 3 DSGVO)
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
           wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
           Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
           Behörde. (Art. 77 DSGVO)
         </span>
       </p>
       <p className="c7">
         <span className="c4">
           Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
           Anschrift finden Sie unter:&nbsp;
         </span>
         <span className="c1">
           <a
             className="c16"
             href="https://www.google.com/url?q=https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html&sa=D&source=editors&ust=1710784823152723&usg=AOvVaw2z56x9xWTpNPRey0qNsAyV"
           >
             https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
           </a>
         </span>
         <span className="c4">.</span>
       </p>
       <p className="c7">
         <span className="c4">
           In unserem Fall ist die bayrische Aufsichtsbehörde zuständig:{" "}
         </span>
         <span className="c1">
           <a
             className="c16"
             href="https://www.google.com/url?q=https://www.lda.bayern.de/&sa=D&source=editors&ust=1710784823153038&usg=AOvVaw3QeUjakLd09xpociS0V7Ia"
           >
             https://www.lda.bayern.de
           </a>
         </span>
       </p>
       <p className="c3">
         <span className="c4" />
       </p>
       <p className="c7">
         <span className="c4">
           Letzte Anpassungen an der Datenschutzerklärung nach aktuellen
           Rechtsprechungen wurden am 02.03.2024 vorgenommen.
         </span>
       </p>
       <p className="c2">
         <span className="c4" />
       </p>

     </div>
<div className="travel-card-wrapper">
    </div>
</p>
    </div>
  </>


    );
  }
}

export default Datenschutz;
