import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, message, Row, Spin, Switch, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import MapPicker from "./MapPicker";  // You might want to reuse the MapPicker component

import { API } from "../constant";
import { getToken } from "../helpers";
import { useAuthContext } from "../context/AuthContext";

const dateFormat = 'YYYY-MM-DD HH:mm:ss';


const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return (e || {}).fileList;
};

const ProduktProfile = ({ type, id, produktData }) => {
const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");


  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [form] = Form.useForm();

    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
        }
    }, [form, defaultValues])

  useEffect(() => {
    const produkt = produktData.find((produkt) => produkt.id == id) || {};
    setDefaultValues({
      image: produkt.image,
      Homepage: produkt.Homepage,
      description: produkt.description,
      name: produkt.name,
      Kategorie: produkt.Kategorie,
      Hersteller: produkt.Hersteller,
    });
  }, [produktData]);

  const handleProduktUpdate = async (data) => {
    setLoading(true);
    try {
      const produkt = produktData.find((produkt) => produkt.id === id) || {};
      const response = await fetch(`${API}/produkt/${produkt.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      // Update logic based on your application's requirements
      message.success("Daten erfolgreich gespeichert!");
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card className="produkt_profile_card">
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          onFinish={handleProduktUpdate}
        >
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Beschreibung"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Beschreibung ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input.TextArea placeholder="Nähere Beschreibung..." rows={6} />
              </Form.Item>
            </Col>
             {defaultValues.image && <Col span={12}><img className={"leafImage leafImage--veranstaltung"} src={defaultValues.image}  /> </Col> }
                <Col span={defaultValues.image ? 8 : 24}>
                   <Form.Item label="Bild hochladen" name="image">
                        <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                          <Upload.Dragger name="files" action="/upload.do">
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Bild oder Video in diesen Bereich</p>
                            <p className="ant-upload-hint">Bilder im Format .pdf, .png, .jpeg, .jpg werden unterstützt - Videos im Format .mp4 oder .webm</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Form.Item>
                  </Col>
            <Col span={12}>
              <Form.Item
                label="Kategorie"
                name="Kategorie"
                rules={[
                  {
                    required: true,
                    message: "Kategorie ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Kategorie" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Hersteller"
                name="Hersteller"
                rules={[
                  {
                    required: true,
                    message: "Hersteller ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Hersteller" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Externer Link"
                name="Homepage"
              >
                <Input placeholder="beispiel.de" addonBefore="https://" />
              </Form.Item>
            </Col>
        <Divider />

            <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 800}}>Profil veröffentlichen</span>}
                name="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            { selectedSubTitle === "Hersteller: Premium" && <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 400}}>Fokussiere Profil</span>}
                name="Fokusiere"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col> }
          </Row>
          <Button
            className="produkt_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading ? (
              <>
                <Spin size="small" /> Speichern...
              </>
            ) : (
              "Speichern"
            )}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ProduktProfile;
