import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API, API_BASE } from '../constant';
import { getToken } from "../helpers";

export const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return (e || {}).fileList;
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const PicturesWall = ({wallId, fileList, setFileList}) => {
   const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {

    console.debug("file",file);
      if (!file.thumbUrl && !file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.thumbUrl || file.url || file.preview || ((((file || {}).attributes || {}).formats || {}).thumbnail || {}).url);
      setPreviewOpen(true);
      setPreviewTitle((((file || {}).attributes) || {}).name || file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </button>
    );
    return (

               <>
                    <Upload
                      name="files"
                      crossOrigin={"use-credentials"}
                      action={`${API}/upload`} headers={{ "Authorization": `Bearer ${getToken()}`}}
                      listType={"picture-card"}
                      fileList={(fileList || []).map((file) => {
                          console.debug("file", file);
                          if (!file.thumbUrl) {
                              if ((((file || {}).response || {})[0] || {}).url) {
                               file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                              } else {
                                file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                              }
                          }


                            return file;
                      })}
                      data={{
                          ref: "api::hersteller.hersteller",
                          field: "Medien",
                        }}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                        alt={previewTitle}
                        style={{
                          width: '100%',
                        }}
                        src={previewImage}
                      />
                    </Modal>
                </>
    );
  };