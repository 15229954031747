import React, { Component } from 'react';
import { Button,Badge, Rate, message } from 'antd';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import { Calendar, List, Avatar } from 'antd';
import Autocomplete from './Autocomplete';
import ContactForm from '../components/ContactForm';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import MedienCarousel from './MedienCarousel';
import { useAuthContext } from "../context/AuthContext";
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

import { API_BASE } from '../constant';
import { Row, Col,Divider, Typography, Tag } from 'antd';

const { Title, Text } = Typography;

const desc = ['absolut nicht empfehlendswert', 'nicht empfehlendswert', 'befriedigend', 'empfehlendswert', 'sehr empfehlendswert'];

const columns = [
  { title: '', prop: 'image', render: (image) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${image})` }}></div> },
  { title: 'Veranstaltung', prop: 'label', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum', prop: 'date', sortable: true, render: (date) => {
  var date = new Date(date);

  // Extracting day, month, and year components
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 as months are 0-based
  var year = date.getFullYear() - 2000; // Assuming you want to represen

  var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
  return <span>{formattedDate}</span> }},
  { title: 'R-Punkte', prop: 'points', render : (points) => <span className="points">{points}</span> },
];


const EventDetails = ({ eventDetails }) => {
    const [showContact, setShowContact] = React.useState(false);

const headerRender = () => null;
const getListData = (valued) => {
  let listData;
  const value = valued.add(-1, 'day')
  const dayString = value.toISOString().substr(0,value.toISOString().indexOf("T"));

  const av = eventDetails.Terminverfuegbarkeit;
  if (av && Array.isArray(av) && av.find( a => a.indexOf(dayString) > -1)) {
        listData = [
            {
              type: 'success',
              content: 'Verfügbar',
            },
        ];
  }

   return listData || [];
}
 const dateCellRender = (value) => {

    const listData = getListData(value);
    return (
      <ul style={{margin: 0, marginTop: -12,
        padding: 0,
        listStyle:'none'
        }} className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

 const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
//    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  return (
    <div style={{ padding: '20px', border: '1px solid #355fb3' }}>
      <Divider orientation="left">Alle Informationen auf einen Blick</Divider>
      <Row gutter={[24, 24]}>
      {eventDetails.Titel && <Col xs={24} sm={24}>
                  <Text  ><strong>Titel:</strong> {eventDetails.Titel}</Text>
                </Col>}

          {eventDetails.Name && <Col xs={24} sm={24}>
            <Text  ><strong>Name:</strong> {eventDetails.Name}</Text>
            {eventDetails.Vorname && <Text  >, {eventDetails.Vorname}</Text>}
          </Col>}


          {eventDetails.Einsatzgebiet && <Col xs={24} sm={24}>
            <Text><strong>Einsatzgebiet Radius:</strong> {eventDetails.Einsatzgebiet.substr(1)} km</Text>
          </Col>}



          {eventDetails.AnbieterNummer && <Col xs={24} sm={24}>
            <Text><strong>Anbieter Nummer:</strong> {eventDetails.AnbieterNummer}</Text>
          </Col>}



          {eventDetails.Ansprechpartner && <Col xs={24} sm={24}>
            <Text><strong>Ansprechpartner:</strong> {eventDetails.Ansprechpartner}</Text>
          </Col>}



          {eventDetails.Teilnehmergebuehr && <Col xs={24} sm={24}>
            <Text><strong>Teilnehmergebuehr:</strong> {eventDetails.Teilnehmergebuehr.substr(1)}</Text>
          </Col>}




          {eventDetails.PLZ && <Col xs={12} sm={12}>
            <Text><strong>PLZ:</strong> {eventDetails.PLZ}</Text>
          </Col>}
          {eventDetails.Stadt &&
            <Col xs={12} sm={12}><Text><strong>Stadt:</strong> {eventDetails.Stadt}</Text></Col>
          }


          {eventDetails.Seminartyp && Array.isArray(eventDetails.Seminartyp) && (
            <Col xs={24} sm={24}>
              <Text><strong>Seminartyp: </strong></Text>
              {(eventDetails.Seminartyp || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}

          {eventDetails.Grundqualifikation && Array.isArray(eventDetails.Grundqualifikation) && (
            <Col xs={24} sm={24}>
              <Text><strong>Grundqualifikation: </strong></Text>
              {(eventDetails.Grundqualifikation || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}

          {eventDetails.Zusatzqualifikation && Array.isArray(eventDetails.Zusatzqualifikation) && (
            <Col xs={24} sm={24}>
              <Text><strong>Zusatzqualifikation: </strong></Text>
              {(eventDetails.Zusatzqualifikation || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}



          {eventDetails.FortbildungsinhalteWundexperteICW && Array.isArray(eventDetails.FortbildungsinhalteWundexperteICW) && (
            <Col xs={24} sm={24}>
              <Text><strong>Fortbildungsinhalte Wundexperte ICW: </strong></Text>
              {(eventDetails.FortbildungsinhalteWundexperteICW || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}


          {eventDetails.FortbildungsinhalteFachtherapeutWundeICW && Array.isArray(eventDetails.FortbildungsinhalteFachtherapeutWundeICW) && (
            <Col xs={24} sm={24}>
              <Text><strong>Fortbildungsinhalte Fachtherapeut Wunde ICW: </strong></Text>
              {(eventDetails.FortbildungsinhalteFachtherapeutWundeICW || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}


          {eventDetails.FortbildungsinhalteÄrztlicherWundexperteICW && Array.isArray(eventDetails.FortbildungsinhalteÄrztlicherWundexperteICW) && (
            <Col xs={24} sm={24}>
              <Text><strong>Fortbildungsinhalte Ärztlicher Wundexperte ICW: </strong></Text>
              {(eventDetails.FortbildungsinhalteÄrztlicherWundexperteICW || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}


          {eventDetails.FortbildungsinhaltePflegetherapeutWundeICW && Array.isArray(eventDetails.FortbildungsinhaltePflegetherapeutWundeICW) && (
            <Col xs={24} sm={24}>
              <Text><strong>Fortbildungsinhalte Pflegetherapeut Wunde ICW: </strong></Text>
              {(eventDetails.FortbildungsinhaltePflegetherapeutWundeICW || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}

        <Col xs={24} sm={24}><Title level={5}>  <strong>  Honorarvorstellung</strong></Title> </Col>
          {eventDetails.HonorarvorstellungProUnterrichtseinheit && <Col xs={24} sm={7}>
            <Text> <strong>{eventDetails.HonorarvorstellungProUnterrichtseinheit.substr(1)} €</strong> <i>Pro Unterrichtseinheit</i></Text>
          </Col>}
          {eventDetails.HonorarvorstellungPauschaleHalberTag && <Col xs={24} sm={7}>
            <Text> <strong>{eventDetails.HonorarvorstellungPauschaleHalberTag.substr(1)} €</strong> <i>Pauschale halber Tag</i> </Text>
          </Col>}
          {eventDetails.HonorarvorstellungPauschaleGanzerTag && <Col xs={24} sm={7}>
            <Text> <strong>{eventDetails.HonorarvorstellungPauschaleGanzerTag.substr(1)} €</strong> <i>Pauschale ganzer Tag</i> </Text>
          </Col>}

<Calendar cellRender={cellRender} fullscreen={false} />
          {eventDetails.Email && <Col xs={24} sm={24}>
          <br />
           <Button block style={{ width: '30%'}} onClick={ () => setShowContact(true)} >Kontaktieren</Button>
           { showContact && <ContactForm to={eventDetails.Email} /> }
          </Col>}


      {eventDetails.ExternerLink && <div style={{ width: '100%', textAlign: 'center'}}>Mehr Informationen unter: <a href={"https://" + eventDetails.ExternerLink}>{"https://" + eventDetails.ExternerLink}</a> </div>}
      </Row>
    </div>
  );
};

const columnsExtended = [ ...columns,
  { title: 'ICW Zertifizierung', prop: 'ICW', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span> },
  { title: 'DGfw Zertifizierung', prop: 'DGfw', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span>  },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltung extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: '',
      blurUnlocked: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}

 formatDate = (date) => {
      var date = new Date(date);

      // Extracting day, month, and year components
      var day = date.getDate();
      var month = date.getMonth() + 1; // Adding 1 as months are 0-based
      var year = date.getFullYear() - 2000; // Assuming you want to represen

      var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
      return <span>{formattedDate}</span>
  }

  handleSignIn = () => {
    const { history } = this.props;
    history.push('/signin');
  }

  render() {

  const { veranstaltungenData, match , user, sub} = this.props;
  const vId = match.params.id;

  const foundEntity = veranstaltungenData.find( v => v.id === +vId );

  const setValue = (iValue) => {
  console.debug(desc,value,value-1);
  const value = iValue === 0 ? 4 : iValue;
    message.success({
        content: `Ihre Bewertung (${desc[Math.round(value-1)]}) wurde gespeichert.`,
        className: 'custom-class',
        style: {
        },
      });
  }

    return foundEntity ? (
      <>
  <CitySection
  style={{ marginTop: -8 }}
      key={1}
      headline={<h2 style={{ textAlign: 'left', fontWeight: 400, marginBottom: 8 }}>{foundEntity.Vorname} {foundEntity.Name}</h2>}
      description={<SubContent foundEntity={foundEntity}  handleSignIn={ () => this.handleSignIn()} />} /></>
    ) : <h2>Referent nicht gefunden</h2>;
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const SubContent = ({foundEntity, handleSignIn }) => {



  const { user, sub, setSub, availableSubs } = useAuthContext();
const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title || "" : "");

const referenten = ((foundEntity || {}).veranstaltungs || {}).data || [] ;

const denyRead = !(user && (foundEntity || {}).createdby === user.id) && !(user && sub && selectedSubTitle.indexOf("Veranstalter") > -1);

  return (
  <>
        <div className={"leafContainer leafContainer--referent"}  style={{ height: 360, marginLeft: '15%' }}>
         {(((foundEntity.Bild || {}).data || {}).attributes) && <img className={"leafImage leafImage--referent"} style={{  height: 360, borderRadius: '50%', marginLeft: '-15%' }} src={API_BASE + foundEntity.Bild.data.attributes.url}  />}
        <br/>
        { denyRead && <button class="centered-button" onClick={() => handleSignIn()}>
        Um Referenten einsehen zu können, melden Sie sich bitte an und schließen Sie ein Veranstalter Abo ab
            </button> }

          <div className={"row referent " + (!denyRead ? "" : "blur-background")} >
              <div className="col-md-6">

              <p className="leaf--p">
              <span className="leaf--title">Ort</span>
              <span className="leaf--value">{foundEntity.Stadt}</span>
              </p>
              { foundEntity.Einsatzgebiet && <p className="leaf--p">
              <span className="leaf--title">Einsatzradius</span>
              <span className="leaf--value"> {foundEntity.Einsatzgebiet.substr(1)} km</span>
              </p>}

              {foundEntity.Seminartyp && Array.isArray(foundEntity.Seminartyp) && (<p className="leaf--p">
              <span className="leaf--title">Seminartyp</span>
              <span className="leaf--value">{(foundEntity.Seminartyp || []).map(theme => (
                              <Tag key={theme}>{theme}</Tag>
                            ))}</span>
              </p>)}
              <p className="leaf--p">
              <span className="leaf--title">Grundqualifikation</span>
              <span className="leaf--value">{(foundEntity.Grundqualifikation || []).map(theme => (
                              <Tag key={theme}>{theme}</Tag>
                            ))}</span>
              </p>
              <p className="leaf--p">
              <span className="leaf--title">Zusatzqualifikation</span>
              <span className="leaf--value">{(foundEntity.Zusatzqualifikation || []).map(theme => (
                              <Tag key={theme}>{theme}</Tag>
                            ))}</span>
              </p>
              </div>
          </div>
          </div>

        { ((foundEntity.Medien || {}).data || []).length > 0 && <Col xs={12} sm={12}>

                        <MedienCarousel overwriteTitle={"Galerie"} pickedData={foundEntity.Medien.data.map( el => el.attributes)} />
                  </Col> }

{foundEntity.Bio && <Col xs={24} sm={24}>
            <div>
            <Divider orientation="left">Kurzbiografie</Divider>
                    <div className="edDrop" dangerouslySetInnerHTML={{ __html: foundEntity.Bio }} />

                    </div>
                  </Col>}
          <br />
          { (user && sub) && <EventDetails eventDetails={(foundEntity || {})} /> }
          <br />
          <p className="full-desc">{foundEntity.description}</p>
          { foundEntity.Homepage && <p>
          <hr />
  <br />
              <span className="leaf--v">Weitere Informationen:</span>
              <a target="_blank" href={"https://" + foundEntity.Homepage} className="row external__link">https://{foundEntity.Homepage}</a>
          </p>}
          <div  className={((user && sub) ? "" : "blur-background")}><p className="full-desc">{foundEntity.description}</p>{ foundEntity.Homepage && <p>
                                                                              <hr />
                                                                      <br />
                                                                                  <span className="leaf--v">Weitere Informationen:</span>
                                                                                  <a target="_blank" href={"https://" + foundEntity.Homepage} className="row external__link">https://{foundEntity.Homepage}</a>
                                                                              </p>}
                                                                              </div>

      <br />
        </>);
}

const CitySection = ({ style, headline, description, cardsData }) => (
  <div style={style} className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>

    <div className="travel-card-wrapper">

    </div>
  </div>
);

export default withRouter(Veranstaltung);
