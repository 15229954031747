import React, { Component } from 'react';
import { getToken, removeExpire, removeToken } from "./helpers";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);

     if (window.location.hash === '#e=1') {
        removeToken();
        removeExpire();
        window.location.hash = '';
        window.location.replace("/signin");
     } else {
        window.location.hash = "e=1";
        window.location.reload();
     }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1></h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
