import React, { Component } from 'react';
import { actions, dataReducer, utils, Table, Pagination }  from 'react-data-components';

const {
  dataLoaded,
  dataSort,
  dataFilter,
  pageNumberChange,
  pageSizeChange,
} = actions;

const { containsIgnoreCase } = utils;

type Props = {
  pageLengthOptions: Array<number>,
  initialData: Array<any>,
  initialPageLength: number,
  columns: Array<any>,
  keys: Array<string>,
  buildRowOptions: any,
  filters: any,
};

const mapPropsToState = props => ({
  pageSize: props.initialPageLength,
  sortBy: props.initialSortBy,
  prevSort: 'ascending',
});

export default function enhanceDataTable(ComposedComponent) {
  return class DataTableEnhancer extends Component {
    static defaultProps = {
      initialPageLength: 10,
      pageLengthOptions: [5, 10, 20],
      filters: {
        globalSearch: { filter: containsIgnoreCase },
      },
    };

    constructor(props: Props) {
      super(props);
      this.state = dataReducer(
        mapPropsToState(props),
        dataLoaded(props.initialData),
      );
    }

    componentWillReceiveProps(nextProps) {
      this.setState(state =>
        dataReducer(state, dataLoaded(nextProps.initialData)),
      );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.undefined.sortBy !== this.state.undefined.sortBy) {
          // sortBy state has changed
          if (prevState.undefined.sortBy && prevState.undefined.sortBy.prop === this.state.undefined.sortBy.prop) {
              let value = this.state.undefined.sortBy;
              value.order = (this.state.prevSort === 'ascending') ? 'descending' : 'ascending';
              // Update prevSort when sortBy changes
              this.setState({ prevSort:  (this.state.prevSort === 'ascending') ? 'descending' : 'ascending' });
              this.setState(state => dataReducer(state, dataSort(value)));
              // Perform any additional actions you want when sortBy changes
          }
        }
      }

    componentDidMount() {
        this.setState(state => dataReducer(state, dataSort({prop: 'date', order: 'ascending'})));
    }

    onPageNumberChange = value => {
      this.setState(state => dataReducer(state, pageNumberChange(value)));
    };

    onPageSizeChange = ({ target: { value } }) => {
      this.setState(state => dataReducer(state, pageSizeChange(value)));
    };

    onSort = value => {
      this.setState(state => dataReducer(state, dataSort(value)));
    };

    onFilter = (key, { target: { value } }) => {
      this.setState(state =>
        dataReducer(state, dataFilter(key, value, this.props.filters)),
      );
    };

    render() {
      return (
        <ComposedComponent
          onPageNumberChange={this.onPageNumberChange}
          onPageSizeChange={this.onPageSizeChange}
          onSort={this.onSort}
          onFilter={this.onFilter}
          data={(this.state.undefined.sortBy == null) ? { ...this.state.undefined, sortBy: {"order": "descending", prop: "date"}} : this.state.undefined}
          {...this.props}
        />
      );
    }
  };
}
