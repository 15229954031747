import React, { useEffect, useState } from "react";

const CoordinatePicker = ({onSelectCoordinates, defaultMarkerCoordinates}) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Use Leaflet from the global window object
    const { L } = window;

    // Initialize the mapat:
    const leafletMap = L.map("map",{
  dragging: !L.Browser.mobile }).setView(defaultMarkerCoordinates || [51.505, 10.45], defaultMarkerCoordinates ? 13 : 6);
      leafletMap.scrollWheelZoom.disable();

    // Add a tile layer to the map
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(leafletMap);

    // Initialize a marker
    const marker = L.marker(defaultMarkerCoordinates || [51.505, 10.45], {
      draggable: true, // Allow marker to be dragged
    }).addTo(leafletMap);

    // Event listener for when the marker is dragged
    marker.on("dragend", function (event) {
      const coordinates = event.target.getLatLng();
      console.log("Selected Coordinates:", coordinates);
      onSelectCoordinates([coordinates.lat, coordinates.lng].join(", "));
//      alert(
//        "Selected Coordinates: " + coordinates.lat + ", " + coordinates.lng
//      );
    });

    setMap(leafletMap);

    // Clean up the map when the component is unmounted
    return () => {
      leafletMap.remove();
    };
  }, [defaultMarkerCoordinates]);

  return <div id="map" style={{ height: "400px" }} />;
};

export default CoordinatePicker;
