import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,Select,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken, setExpire } from "../../helpers";

const { Option } = Select;

const PasswordNew = (props) => {
  const { isDesktopView } = useScreenSize();

  const { setUser, setUserAndPlan } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email: values.email}),
      });

      const data = await response.json();
      if ((data || {}).error) {
        message.error('Es ist etwas schief gelaufen');
        console.error((data || {}).error);
      } else {
        message.success('Falls ein Konto mit dieser Email existiert, wird eine Email mit weiteren Anweisungen versendet.');
      }
    } catch (error) {
      console.error(error);
      message.error('Es ist etwas schief gelaufen');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Passwort ändern">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Email senden {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              Sie erinnern sich wieder? <Link to="/signin">Zur Anmeldung</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(PasswordNew);
