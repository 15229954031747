import React, { Component } from 'react';
import Slider from 'react-slick';
import ExperienceCard from './ExperienceCard';
import { Link } from 'react-router-dom';
import { Left } from './Arrows.js';
import { Right } from './Arrows';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Styles/headout-picks.css';
import { API_BASE } from '../constant';

class TopPicks extends Component {
  render() {
    const { pickedData, all, noFocus } = this.props;
    return (
      <div>
        <div style={{  }} className="headout-picks-wrapper">
          <div className="headout-picks-nav">
            <div>
              <h1>{this.props.headline}</h1>
            </div>
            { all !== false && <div className="view-all">
                <Link
                  to={{ pathname: `/hersteller` }}
                  key={'v_ha'}
                  className="link_sa view-all"
                >
                  <h3>Alle anzeigen</h3>
                  <i className="fas fa-arrow-right" />
                </Link>
          </div> }
          </div>
          <hr
            style={{
              backgroundColor: '#355fb3',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '20px'
            }}
          />
          <div className="top-picked-carousel-wrap">
            <PickedSlider pickedData={pickedData} noFocus={noFocus} />
          </div>
        </div>
      </div>
    );
  }
}

class PickedSlider extends React.Component {
  render() {



    const screenWidth = document.getElementById("root").clientWidth;

    var settings = {
      infinite: true,
      adaptiveHeight: true,
      speed: 500,
      slidesToShow: screenWidth > 756 ? 4 : 1,
      rows: 1,
      slidesToScroll: screenWidth > 756 ? 2 : 1,
      initialSlide: 0,
      nextArrow: <Right />,
      prevArrow: <Left />,

    };
    const { pickedData, noFocus } = this.props;
    return (
      <Slider {...settings}>
        {pickedData &&
          pickedData.map(
            ({
              id,
              name,
              city,
              image,
              Produktbild,
              Produktbeschreibung,
              Produktname,
              type,
              description,
              currency,
              currentPrice,
              ratings,
              Hersteller,
              Bewertung,
              discount,
              focus,
              Preis,
              about
            }) => {

                        const resPath = type === "Hersteller" ? "hersteller" : "produkt";
                        return (<Link
                          to={{ pathname: `/${resPath}/${id}` }}
                          key={id}
                          className="link"
                        >
              <ExperienceCard
                key={id}
                city={name}
                about={about}
                noFocus={noFocus}
                justImages={type === "Hersteller"}
                url={(((Produktbild || {}).data || {}).attributes || {}).url ? (API_BASE + Produktbild.data.attributes.url) : (image || '/pmp.png')}
                description={Hersteller ? (Produktbeschreibung + " von " + Hersteller) : description}
                ratings={ratings}
                stars={Bewertung}
                discount={discount}
                cashback={focus}
              />
                        </Link>)
            }
          )}
      </Slider>
    );
  }
}

export default TopPicks;
