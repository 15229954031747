import React, {useState, useEffect} from "react";
import {Modal, Button} from "antd";
import {ZoomInOutlined, ZoomOutOutlined} from '@ant-design/icons';
import PDF from "react-pdf-js";
import { saveAs } from 'file-saver';


const PdfViewer = ({name, pdf, onCancel})=> {

    const [showPdf, setShowPdf] = useState(false)
    const [myPdf, setMyPdf] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [scale, setScale] = useState(1);

    const onDocumentComplete = (numPages) =>{
      setPages(numPages)
    }

  useEffect(() => {
    // Fetch the PDF as a blob to enable downloading
    const fetchPdf = async () => {
      const response = await fetch(pdf);
      const blob = await response.blob();
      setPdfBlob(blob);
    };

    fetchPdf();
  }, [pdf]);
    const handleDownload = () => {
      if (pdfBlob) {
        saveAs(pdfBlob, name );
      }
    };
    const onDocumentError = (err) => {
      console.error('pdf viewer error:', err);
    }

    const onSetScale = (type) =>{

        var newScale = type ? scale + 0.1 : scale - 0.1;

        if (newScale > 2){
            newScale = 2
        } else if (newScale < 0.1){
            newScale = 0.1
        }

        setScale(newScale)

    }

    const onPage = (type) =>{

      var newPage = type ? page + 1 : page - 1

      if (newPage > pages){
        newPage = 1
      } else if (newPage < 1){
        newPage = pages
      }

      setPage(newPage)
    }

    const zoomStyle = {
        marginLeft: 10,
        cursor: 'pointer'
    }

    const footer = <div className="footer">
       <Button onClick={()=>onPage(0)}>Vorherige Seite</Button>
       <div>
       <span style={{textAlign: 'center'}}>Page {page} of {pages}</span>
           <ZoomOutOutlined style={{...zoomStyle, opacity: scale === 0.1 ? 0.5 : 1}} onClick={()=>onSetScale(0)}/>
           <ZoomInOutlined style={{...zoomStyle, opacity: scale === 2 ? 0.5 : 1}} onClick={()=>onSetScale(1)}/>
           <span>{Math.round(scale * 100)}%</span>
        </div>
       <Button onClick={()=>onPage(1)}>Nächste Seite</Button>
    </div>

    return (<>

    <Modal maskClosable={false}
                   onCancel={() => setShowPdf(false)}
                   visible={showPdf}
                   width={"50%"}
                   bodyStyle={{height: 600, overflowY: 'auto'}}
                   style={{ top: 20 }}
                   footer={footer}

    >
    <div className="pdfWrapper">
        <PDF
            file={pdf}

            onDocumentComplete={onDocumentComplete}
            onDocumentError={onDocumentError}
            page={page}
            scale={scale}
        />
    </div>
    </Modal>
    <Button disabled={false} style={ { marginLeft: '1em' } } onClick={handleDownload}>Download PDF</Button>
    <Button disabled={false} style={ { marginLeft: '1em' } } onClick={()=>setShowPdf(!showPdf)}>PDF Datei anzeigen</Button>
    </>)

};
export default PdfViewer;