import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { Button, Drawer, Radio } from 'antd';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import InserierenLeaf from "../components/Veste/InserierenLeaf";
import { getToken } from "../helpers";
import './Styles/new-york.css';
import { useAuthContext } from "../context/AuthContext";

const InserierenContent = (props) => {
    const { availableSubs, user, sub } = useAuthContext();
    const { type, history, id, veranstaltungenData } = props;

    const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title || "" : false || "");
    const subType = (selectedSubTitle || "").substr(0, (selectedSubTitle || "").indexOf(":"))
    const userRolle = (user || {}).Rolle;

    console.debug('subType', subType, 'selectedSubTitle', selectedSubTitle, 'sub', sub, 'availableSubs', availableSubs);

    if (!subType) {
        return (<>
            <div className="site-drawer-render-in-current-wrapper">
                Sie haben noch kein Abonnement ausgewählt. Bitte wählen Sie einen Abonnement aus, um fortzufahren.
                <p>
                    <Link to="/admin/plans">Zu den Abonnements</Link>
                </p>
            </div>
        </>)
    } else if (!type) {

        const vId = props.veranstaltungenData.find((f,i) => f.rolleType === userRolle && f.createdby === (user || {}).id);
        if (vId && userRolle === "Referent") {
            history.push(`/admin/inserieren/${subType}/${vId.id}`);
        } else if (userRolle === "Referent") {
            history.push(`/admin/inserieren/Referent`);
        } else if (vId && userRolle === "Referent") {
           history.push(`/admin/inserieren/${subType}/${vId.id}`);
        } else if (userRolle === "Veranstalter") {
           history.push(`/admin/inserieren/Veranstaltung`);
        } else if (userRolle === "Hersteller") {
           history.push(`/admin/inserieren/Hersteller`);
        } else if (userRolle === "Versorgungspartner") {
           history.push(`/admin/inserieren/Versorgungspartner`);
        }



    }

    return (
        <>
         { !type && <div className="site-drawer-render-in-current-wrapper">
                 Welche Art von Inserat möchten Sie erstellen?
                 <div
                   style={{
                     marginTop: 16,
                   }}
                 >
                       <Radio.Group size="large" onChange={e => history.push(`/admin/inserieren/${e.target.value}/`)}>
                         <Radio.Button value="Veranstaltung">Veranstaltung</Radio.Button>
                         <Radio.Button value="Referent">Referent</Radio.Button>
                         <Radio.Button value="Hersteller">Hersteller</Radio.Button>
                         <Radio.Button value="Produkt">Produkt</Radio.Button>
                         <Radio.Button value="Versorgungspartner">Versorgungspartner</Radio.Button>
                       </Radio.Group>
                 </div>
               </div> }
            { type && <InserierenLeaf type={type} id={id} veranstaltungenData={veranstaltungenData} /> }
            </>
    );
};

class Inserieren extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      open: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {


    const token = getToken();

    if (!token) {
        this.props.history.push(`/signin`);
    }

    const { match } = this.props;
    const { type, id } = match.params;

    // /signin

    return (
      <>
<div className="city-section-wrapper static-padding"><br /><br /><br /><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {type} Inserieren
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <InserierenContent history={this.props.history} type={type} id={id} veranstaltungenData={this.props.veranstaltungenData} />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default withRouter(Inserieren);
