// Autocomplete.js

import React, { useState, useEffect } from 'react';

const Autocomplete = ({ history, resourcePath, suggestions, onChange, value }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    // Update filteredSuggestions when suggestions change
    setFilteredSuggestions(
      suggestions.map((s) => s.Vorname ? (s.Vorname + ' ' + s.Name) : (s.label || s.name || s.Name || s.Produktname  || s.Veranstaltungsname)).filter(
        (suggestion) => {
            return (suggestion || "").toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          }
      )
    );
  }, [suggestions, inputValue]);

  const handleChange = (e) => {
    const userInput = e.currentTarget.value;
    const filteredSuggestions = (suggestions.map(s => s.Vorname ? (s.Vorname + ' ' + s.Name) : (s.label || s.name || s.Name || s.Vorname || s.Veranstaltungsname || s.Produktname)) || []).filter(
      (suggestion) =>
        (suggestion || "").toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInputValue(userInput);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    onChange(e);
  };

  const handleSelect = (suggestion) => {
    const foundSuggestion = suggestions.find(s => (s.Vorname ? (s.Vorname + ' ' + s.Name) : (s.label || s.name || s.Name || s.Vorname || s.Veranstaltungsname || s.Produktname)) === suggestion);

    const resourcePath = foundSuggestion.type === 'Veranstaltung'
    ? 'veranstaltungen' : foundSuggestion.type === 'Referent'
    ? 'referenten' : foundSuggestion.type === 'Hersteller'  ? 'hersteller' : foundSuggestion.type === 'Versorgungspartner'  ? 'versorgungspartner' : 'produkt';

    history.push(`/${resourcePath}/${foundSuggestion.id}`);
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const renderSuggestions = () => {
    if (filteredSuggestions.length === 0 || !showSuggestions || inputValue === '') {
      return null;
    }

    return (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSelect(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Schnellsuche nach Namen…"
      />

            <i className="fas fa-search" />
      {renderSuggestions()}
    </div>
  );
};

export default Autocomplete;
