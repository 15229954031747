import React, { Component } from 'react';

import { Typography, Divider, Row, Col } from 'antd';
class ExperienceCard extends Component {
  state = {
    listed: false
  };

  addtoWishlist = () =>
    this.setState(prevState => ({ listed: !prevState.listed }));

  render() {
    const url = `url(${this.props.url})`;
    const {
      discount,
      cashback,
      currency,
      lastPrice,
      ratings,
      stars,
      city,
      about,
      showMore,
      highlight,
      justImages,
      noFocus
    } = this.props;

    let style;

    if (this.state.listed) {
      style = {
        color: '#f43361',
        fontSize: '20px',
        position: 'absolute',
        right: '10px',
        top: '15px',
        fontWeight: '700',
        transition: 'transform 0.3s ease-in-out'
      };
    } else {
      style = {
        color: 'white',
        fontSize: '20px',
        position: 'absolute',
        right: '10px',
        top: '15px',
        fontWeight: '400',
        transition: 'transform 0.3s ease-in-out'
      };
    }

    return (
      <div className={ justImages ? "exp-card--images" : "exp-card"}>
        {showMore ? (
          <div className="show-more">
            <p>Alle anzeigen</p>
          </div>
        ) : (
          <React.Fragment>
            <div
              className="exp-card-img"
              style={{
                backgroundImage: url
              }}
            >
              {(cashback &&  !justImages && noFocus !== false)  ? (
                <div className="cashback">
                  <p>{`Im Fokus`}</p>
                </div>
              ) : null}
              {highlight ? (
                <div className="cashback">
                  <p>{highlight}</p>
                </div>
              ) : null}
              { false && <div className="exp-heart">
                <i
                  className="far fa-heart"
                  role="button"
                  onClick={this.addtoWishlist}
                  style={style}
                />
              </div>}
            </div>
            { !justImages && <div className="exp-content-wrap">
              <div className="exp-info-wrap">
                {city ? (
                  <React.Fragment>
                    <p id="exp-city">{city}</p>
                    <p id="exp-description">{this.props.description}</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p id="exp-about">{about}</p>

        { this.props.description && <div id="exp-description">
                            <div className="oneLine edDrop" dangerouslySetInnerHTML={{ __html: this.props.description }} />

                            </div>}
                  </React.Fragment>
                )}
              </div>
              <div>
                <div className="price-section">
                  <div className="div">
                  </div>
                </div>
              </div>
            </div> }
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ExperienceCard;
