
import React, { useEffect, useState } from 'react';

import AuthProvider from "./components/AuthProvider/AuthProvider";
import Header from './Components/Header';
import Footer from './Components/footer';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainHome from './Components/MainHome';
import NewYork from './Components/NewYork';
import Veranstaltungen from './Components/Veranstaltungen';
import Veranstaltung from './Components/Veranstaltung';
import Referenten from './Components/Referenten';
import Versorgungspartner from './Components/Versorgungspartner';
import VersorgungspartnerLeaf from './Components/Versorgungspartner-leaf';
import Referent from './Components/Referent';
import Info from './Components/Info';
import Erfolg from './Components/Erfolg';
import Plans from './Components/Plans';
import Datenschutz from './Components/Datenschutz';
import Benutzerkonto from './Components/Benutzerkonto';
import MeineInserate from './Components/Meine-Inserate';
import Inserieren from './Components/Inserieren';
import SignInUp from './Components/SignInUp';
import Impressum from './Components/Impressum';
import Hersteller from './Components/Hersteller';
import HerstellerLeaf from './Components/Hersteller-Leaf';
import Produkt from './Components/Produkt';
import AppStore from './Components/AppStore';
import Profile from "./components/Profile/Profile";
import { API } from "./constant";
import { getToken, removeToken, getUserE, getExpire } from "./helpers";

import { useAuthContext } from "./context/AuthContext";


// Caraousel Images for Home

const backgroundImagesData = [
  {
    id: 1,
    url: '/pmp-header-1.jpg',

  },
  {
    id: 2,
    url: '/pmp-header-2.jpg',

  },
  {
    id: 3,
    url: '/pmp-header-3.jpg',

  },
  {
    id: 4,
    url: '/pmp-header-4.jpg',

  }
];

// Header Navigation Data

const HeaderNavDataC1 = [
   {
            id: 0,
            name: 'Suchen',
            url: ''
          },
        {
          id: 1,
          name: 'Veranstaltungen',
           url: 'veranstaltungen'
        },
        {
          id: 2,
          name: 'Referenten',
         url: 'referenten'
        },
        {
          id: 3,
          name: 'Hersteller/ Produkte',
         url: 'hersteller'
        }
];
const HeaderNavDataC2 = [
        {
          id: 4,
          name: 'Versorgungspartner',
         url: 'versorgungspartner'
        },
     {
       id: 5,
       name: 'Über PMP.online',
      url: 'info'
     }
];

const HeaderNavData = [
   {
            id: 0,
            name: 'Suchen',
            url: ''
          },
        {
          id: 1,
          name: 'Veranstaltungen',
           url: 'veranstaltungen'
        },
        {
          id: 2,
          name: 'Referenten',
         url: 'referenten'
        },
        {
          id: 3,
          name: 'Hersteller/ Produkte',
         url: 'hersteller'
        },
        {
          id: 4,
          name: 'Versorgungspartner',
         url: 'versorgungspartner'
        },
     {
       id: 5,
       name: 'Über PMP.online',
      url: 'info'
     }
];



const PageRoutes = () => {
  const { user, sub, setUser, setUserAndPlan } = useAuthContext();
  const [veranstaltungenData, setVeranstaltungenData] = useState([]);
  const [filterAdvancedHighlighted, setFilterAdvancedHighlighted] = useState(true);

    const fetchData = async () => {
      try {
//        const response = await fetch('/dummyVeranstaltungen.json');
        const responseVeranstaltungen = await fetch(`${API}/veranstaltungs?populate=Bild,Medien,Fortbildungspunkte,referents,herstellers,ExterneReferenten,ExterneHersteller,referents.Bild,herstellers.Logo`);
        const responseReferenten = await fetch(`${API}/referents?populate=Bild,Medien,veranstaltungs,veranstaltungs.Bild`);
        const responseHersteller = await fetch(`${API}/herstellers?populate=Logo,Medien,veranstaltungs,veranstaltungs.Bild,veranstaltungs,Produkt,Produkt.Produktgroesse,Produkt.Medien,Produkt.Produktbild,Produkt.Datenblaetter`);
        const responseVersorgungspartner = await fetch(`${API}/versorgungspartners?populate=Logo,Medien`);

        const responseVeranstaltungenData = await responseVeranstaltungen.json();
        const responseReferentenData = await responseReferenten.json();
        const responseHerstellerData = await responseHersteller.json();
        const responseVersorgungspartnerData = await responseVersorgungspartner.json();

        const cleanedVeranstaltungen = responseVeranstaltungenData.data.map((d) => {
            return { ...d.attributes, id: d.id, rolleType: 'Veranstalter', type: 'Veranstaltung' };
        });

        const cleanedReferenten = responseReferentenData.data.map((d) => {
            return { ...d.attributes, id: d.id, rolleType: 'Referent', type: 'Referent' };
        });

        const cleanedHersteller = responseHerstellerData.data.map((d) => {
            return { ...d.attributes, id: d.id, rolleType: 'Hersteller', type: 'Hersteller' };
        });

        const cleanedVersorgungspartner = responseVersorgungspartnerData.data.map((d) => {
            return { ...d.attributes, id: d.id, rolleType: 'Versorgungspartner', type: 'Versorgungspartner' };
        });

        const cleanedProdukt = []

        cleanedHersteller.forEach( (h) => {
            h.Produkt.forEach((d) => {
                cleanedProdukt.push({ ...d,
                 HerstellerId: h.id,
                 Hersteller: h.Herstellername, type: 'Produkt' });
            })
        } );

        const data = [];// await response.json();
        // Assuming data is an array of coordinates, e.g., [{ lat: 51.34, lng: 12.39 }, { lat: 55.34, lng: 10.39 }]
        setVeranstaltungenData([...data, ...cleanedVeranstaltungen, ...cleanedReferenten, ...cleanedProdukt, ...cleanedHersteller, ...cleanedVersorgungspartner]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  useEffect(() => {
    // This code will be executed after the component is mounted to the DOM


    fetchData();

    // You can perform actions, such as data fetching or initialization, here
    // If you want to perform cleanup when the component is unmounted, return a function from useEffect
    return () => {
      window.scrollTo(0, 0);
      window.onscroll = function() {
          // Get the header
          var header = document.getElementsByClassName("search-bar-div")[0];
          var topSearch = document.getElementById("topSearch");

          // Get the offset position of the navbar
          if (header) {
                var sticky = header.offsetTop - 100;
                if (window.pageYOffset > sticky) {
                  topSearch.style.opacity = "1";
                } else {
                  topSearch.style.opacity = "0";
                }
          }
      };
    };



  }, []);

    return(
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <div className="App">
              <Header
                backgroundImagesData={backgroundImagesData}
                navigationData={HeaderNavData}
                navigationDataC1={HeaderNavDataC1}
                navigationDataC2={HeaderNavDataC2}
                setFilterAdvancedHighlighted={setFilterAdvancedHighlighted}
                filterAdvancedHighlighted={filterAdvancedHighlighted}
                veranstaltungenData={veranstaltungenData}
              />
              <div className="contentWrap">
                  <Route exact path="/" render={(props) => <MainHome veranstaltungenData={veranstaltungenData}  additionalProp="value" />} />
                  <Route exact path="/veranstaltungen" render={(props) => <Veranstaltungen  filterAdvancedHighlighted={filterAdvancedHighlighted} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Veranstaltung")}  additionalProp="value" />} />
                  <Route exact path="/veranstaltungen/:id" render={(props) => <Veranstaltung user={user} sub={sub} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Veranstaltung")}  additionalProp="value" />} />
                  <Route exact path="/referenten" render={(props) => <Referenten user={user} sub={sub} filterAdvancedHighlighted={filterAdvancedHighlighted} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Referent")}  additionalProp="value" />} />
                  <Route exact path="/referenten/:id" render={(props) => <Referent user={user} sub={sub} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Referent")}  additionalProp="value" />} />
                  <Route exact path="/versorgungspartner" render={(props) => <Versorgungspartner filterAdvancedHighlighted={filterAdvancedHighlighted} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Versorgungspartner")}  additionalProp="value" />} />
                  <Route exact path="/versorgungspartner/:id" render={(props) => <VersorgungspartnerLeaf veranstaltungenData={veranstaltungenData.filter(d => d.type === "Versorgungspartner")}  additionalProp="value" />} />
                  <Route exact path="/hersteller" render={(props) => <Hersteller filterAdvancedHighlighted={filterAdvancedHighlighted} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Produkt" || d.type === "Hersteller")}  additionalProp="value" />} />
                  <Route exact path="/hersteller/:id" render={(props) => <HerstellerLeaf veranstaltungenData={veranstaltungenData.filter(d => d.type === "Produkt" || d.type === "Hersteller")}  additionalProp="value" />} />
                  <Route exact path="/produkt/:id" render={(props) => <Produkt filterAdvancedHighlighted={filterAdvancedHighlighted} veranstaltungenData={veranstaltungenData.filter(d => d.type === "Produkt" || d.type === "Hersteller")}  additionalProp="value" />} />
                  <Route exact path="/admin/inserieren" render={(props) => <Inserieren veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/inserieren/:type" render={(props) => <Inserieren veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/inserieren/:type/:id" render={(props) => <Inserieren veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/meine-inserate" render={(props) => <MeineInserate veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/benutzerkonto" render={(props) => <Benutzerkonto veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/plans" component={Plans} />
                  <Route exact path="/signin" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/signup" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/password-reset" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/password-new" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/p-success" component={Erfolg} />
                  <Route exact path="/app" component={AppStore} />
                  <Route exact path="/info" component={Info} />
                  <Route exact path="/datenschutz" component={Datenschutz} />
                  <Route exact path="/impressum" component={Impressum} />
              </div>
              <Footer />
          </div>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
    )
};

export default PageRoutes;
