import { createContext, useContext } from "react";

export const AuthContext = createContext({
  user: false,
  sub: false,
  isLoading: false,
  setUser: () => {},
  setUserAndPlan: () => {},
  setSub: () => {},
});

export const useAuthContext = () => useContext(AuthContext);
