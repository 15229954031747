import React, { Component } from 'react';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

class Impressum extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <>
<div className="city-section-wrapper static-padding"><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Impressum
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <div
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      <p className="c1">
        <span className="c6">
          Dieses Impressum gilt für alle Angebote unter der Domain{" "}
        </span>
        <span className="c9 c6">
          <a
            className="c5"
            href="https://www.google.com/url?q=http://www.pmp-online.net&sa=D&source=editors&ust=1710785175773186&usg=AOvVaw1B9XVwRzcbP_PezN9puocg"
          >
            www.pmp-online.net
          </a>
        </span>
        <span className="c0"> inklusive aller Subdomains (Unterseiten).</span>
      </p>
      <p className="c1">
        <span className="c7">Soziale Medien</span>
      </p>
      <p className="c1">
        <span className="c0">
          Dieses Impressum gilt auch für alle untere Auftritte in den folgenden
          sozialen Medien:
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Instagram:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_____________________
        </span>
      </p>
      <p className="c1">
        <span className="c6">
          LinkedIn:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_____________________
        </span>
        <sup>
          <a href="#cmnt1" id="cmnt_ref1">
            [a]
          </a>
        </sup>
      </p>
      <p className="c1">
        <span className="c7">Alle Angaben gemäß §5 TMG</span>
      </p>
      <p className="c1">
        <span className="c0">PMPonline</span>
      </p>
      <p className="c1">
        <span className="c0">Radolfstrasse 18</span>
      </p>
      <p className="c1">
        <span className="c0">96179 Rattelsdorf</span>
      </p>
      <p className="c1">
        <span className="c0">Telefon: +49 176 45869050</span>
      </p>
      <p className="c1">
        <span className="c6">E-Mail: </span>
        <span className="c6 c9">
          <a className="c5" href="mailto:info@pmp-online.net">
            info@pmp-online.net
          </a>
        </span>
        <sup>
          <a href="#cmnt2" id="cmnt_ref2">
            [b]
          </a>
        </sup>
      </p>
      <p className="c1">
        <span className="c7">Eintragungen</span>
      </p>
      <p className="c1">
        <span className="c0">
          Register:
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;______________________
        </span>
      </p>
      <p className="c1">
        <span className="c6">
          Registernummer:
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;______________________
        </span>
        <sup>
          <a href="#cmnt3" id="cmnt_ref3">
            [c]
          </a>
        </sup>
      </p>
      <p className="c1">
        <span className="c7">Umsatzsteuer-Identifikationsnummer: 94 315 870 067</span>
      </p>
      <p className="c1">
        <span className="c6">Sparkasse Bamberg</span>
      </p>


      <p className="c1">
        <span className="c6">
                             IBAN: DE13770500000300743002</span>
      </p>
      <p className="c1 c8">
        <span className="c7" />
      </p>
      <p className="c1">
        <span className="c7">Haftung für Inhalte</span>
      </p>
      <p className="c1">
        <span className="c6">
          Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf
          diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis §
          10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder
          nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
          nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
          diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
          konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
          Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </span>
      </p>
      <p className="c1 c8">
        <span className="c7" />
      </p>
      <p className="c1">
        <span className="c7">&nbsp;</span>
      </p>
      <p className="c1 c8">
        <span className="c7" />
      </p>
      <p className="c1">
        <span className="c7">Haftung für Links</span>
      </p>
      <p className="c1">
        <span className="c0">
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
          Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
          verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
          auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
          Zeitpunkt der Verlinkung nicht erkennbar.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
          entfernen.
        </span>
      </p>
      <p className="c1">
        <span className="c7">Urheberrecht</span>
      </p>
      <p className="c1">
        <span className="c0">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
          des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
          Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
          privaten, nicht kommerziellen Gebrauch gestattet.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
          werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
          Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
          wir derartige Inhalte umgehend entfernen.
        </span>
      </p>
      <p className="c1">
        <span className="c7">Widerspruch gegen Werbe-E-Mails</span>
      </p>
      <p className="c1">
        <span className="c0">
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
          und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
          Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
          unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails,
          vor.
        </span>
      </p>


    </div>
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default Impressum;
