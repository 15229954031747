import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,Checkbox,
  Spin,Select,
  Typography,Modal
} from "antd";
import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken, setExpire } from "../../helpers";

const { Option } = Select;

const SignUp = (props) => {
  const { isDesktopView } = useScreenSize();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setUser, setUserAndPlan } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(false);

  const [error, setError] = useState("");

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
    };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);


        if (!checked) {
            message.error('Bitte akzeptieren Sie die Datenschutzbestimmungen');
            return;
        }
        // Submit form logic here
        console.log("Form submitted successfully!");

    try {
      const response = await fetch(`${API}/auth/local/register?locale=de`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if ((data || {}).error) {
        throw (data || {}).error;
      } else {
        // set the token
        setToken(data.jwt);

        const oneDayFromToday = new Date();
        oneDayFromToday.setDate(oneDayFromToday.getDate() + 1);
        setExpire(oneDayFromToday);

        // set the user
        setUser(data.user);

        showModal();
//        message.success(`Es wurde eine Email an ${data.user.email} gesendet, bitte bestätigen sie diese. Danach können Sie sich anmelden!`);
      }
    } catch (error) {
        const errorMsg = (error || {}).message;
      console.error(errorMsg);
      if (errorMsg === "Email or Username are already taken") {
        setError("Email oder Username sind bereits vergeben!");
      } else {
        setError(errorMsg || "Etwas lief schief!");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Registrieren">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >

            <Form.Item>
                <Checkbox checked={checked} onChange={handleCheckboxChange}>
                    Ich stimme den <a target="_blank" href="/datenschutz">Datenschutzbestimmungen</a> zu.
                </Checkbox>
            </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>

               <Form.Item
                name="Rolle"
                label="Registrieren als"
                hasFeedback
                rules={[{ required: true, message: 'Registrieren als...' }]}
              >
                <Select placeholder="Registrieren als...">
                  <Option value="Veranstalter">Veranstalter</Option>
                  <Option value="Referent">Referent</Option>
                  <Option value="Hersteller">Hersteller</Option>
                  <Option value="Versorgungspartner">Versorgungspartner</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Passwort"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Passwort" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Registrieren {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              Sie haben schon ein Nutzerkonto? <Link to="/signin">Zur Anmeldung</Link>
            </Typography.Paragraph>
          </Card>
          <Modal footer={null} title="Email bestätigen" open={isModalOpen} onCancel={handleOk}>
                  <p>Es wurde eine Email an Ihre hinterlegte Adresse gesendet, bitte bestätigen sie diese. Danach können Sie sich anmelden!</p>
                </Modal>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(SignUp);
