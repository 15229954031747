import React, { Component, useContext } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,List,
  Spin,Popconfirm,
  Typography,
} from "antd";
import { InboxOutlined, UploadOutlined, CheckSquareOutlined } from '@ant-design/icons';

import { API, STRAPI_API, BEARER } from "../constant";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";

const confirm = (e) => {
  console.log(e);
  message.success('Abo storniert');
};
const cancel = (e) => {
  console.log(e);
  message.error('Abo nicht storniert');
};

     const PlansConsumer = (props) => {
        const { availableSubs, getSubsAvailable, user, sub, setSub } = useContext(AuthContext);
        if (!availableSubs || availableSubs.length === 0) {
          getSubsAvailable();
        }

        const planStatus = sub ? (sub.status === "active" && !sub.cancel_at_period_end ? "AKTIV" : "STORNIERT") : false;
        const availableSubsThree = (availableSubs || []).filter(as => as.title.indexOf((user || {}).Rolle) > -1).length > 2;
        console.debug("availableSubs",availableSubs);
         return (
         <div style={{ textAlign: 'left'}}>
             <Row gutter={[18, 18]}>
                 {availableSubs && availableSubs.filter(as => as.title.indexOf((user || {}).Rolle) > -1).map((availableSub, i) => {
                        const rowData = availableSub.description.substr(2).split("\n* ");
                        return <Col md={availableSubsThree ? 7 : 12} lg={availableSubsThree ? 7 : 12} sm={24} xs={24}>
                        <Card
                        onClick={() => props.handleProductClick({ id: availableSub.id, email: (user || {}).email, url:"http://localhost:1337" })}
                        className={"planCard" + (" planCard" + (i+1))}
                        title={availableSub.title}
                        extra={<span><Typography.Title type="secondary" style={{color: '#355fb3'}} level={4}>{availableSub.price} €</Typography.Title> <Typography.Text type="secondary">/ {(((availableSub || {}).title || "").indexOf("Versorgungspartner") > -1 ? "Monat" : "Jahr")}</Typography.Text></span>}
                        bordered={false}>
                                <List
                                      bordered
                                      dataSource={rowData}
                                      renderItem={item => (
                                        <List.Item>
                                          <Typography.Text type="success"><CheckSquareOutlined  style={{color: '#355fb3'}} /> </Typography.Text>  &nbsp;&nbsp;{item}
                                        </List.Item>
                                      )}
                                    />
                              </Card>

                      </Col>
                 })}
              </Row>
        </div>)
     }


class YourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        loading: false,
        error: "",
        user: {},
        isLoading: false,
        availableSubscriptions: false,
        };
    }

  componentDidMount() {
        const params = new URLSearchParams(document.location.search);
        const checkoutSessionId = params.get('sessionId');
        if (checkoutSessionId) {
            this.SS_GetProductPaymentDetails(checkoutSessionId);
        } else {
            this.SS_GetSubStatus("user@keystonejs.com");
        }
  }

  handleProductClick = ({ id, url, email }) => {
    this.SS_ProductCheckout(id, url, email);
  }

  cancelSubscription = ({ subId }) => {
      const cancelSubUrl = `${STRAPI_API}/cancelSubscription/${subId}`;

    try {
        fetch(cancelSubUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json'
          }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                if (json.data.length > 0) {
                    this.setState({ availableSubscriptions: json.data[0].id });
                }
                console.log(json);
              });
            }
          })
        } catch (error) {
          console.error(error);
        }
  }

  SS_ProductCheckout = (productId, baseUrl, userEmail) => {
    const getRedirectUrl = `${STRAPI_API}/getRedirectUrl/${productId}/${(userEmail || "").toLowerCase()}`;

    fetch(getRedirectUrl, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      }),
    })
      .then(response => response.json())
      .then(response => {
        console.debug("response", response);
        if (response.url) {
          window.location.replace(response.url);
        }
      });
  };


  SS_GetProductPaymentDetails = (checkoutSessionId) => {
  console.debug("getToken()", getToken());
    const retrieveCheckoutSessionUrl = `${STRAPI_API}/retrieveCheckoutSession/${checkoutSessionId}`;

    if (
      window.performance
        .getEntriesByType('navigation')
        .map(nav => nav.type)
        .includes('reload')
    ) {
      console.info('website reloaded');
    } else {
      fetch(retrieveCheckoutSessionUrl, {
        method: 'get',
        mode: 'cors',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }),
      })  .then(response => response.json())
              .then(response => {
                console.debug("response", response);
              });
    }
  };

    SS_GetSubStatus = (userEmail) => {
      const retrieveCheckoutSessionUrl = `${STRAPI_API}/getSubscriptionStatus/${(userEmail || "").toLowerCase()}`;

    try {
        fetch(retrieveCheckoutSessionUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json'
          }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                if (json.data.length > 0) {
                    this.setState({ availableSubscriptions: json.data[0].id });
                }
                console.log(json);
              });
            }
          })
        } catch (error) {
          console.error(error);
        }
    };



  render() {
  const { availableSubscriptions } = this.state;
    return (
      <>
      <div className="city-section-wrapper static-padding"><br /><br /><br /><br />
          <h2
            style={{
              textAlign: 'left', marginBottom: 8
            }}
          >
            Abonnements
          </h2>

          <hr
            style={{
              backgroundColor: '#355fb3',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '10px'
            }}
          />
          <PlansConsumer handleProductClick={this.handleProductClick} />
          <p
            style={{
              color: '#545454',
              fontSize: '15.3px',
              marginTop: '0px',
              textAlign: 'left',
              lineHeight: '20px'
            }}
          >
          </p>
          <div className="travel-card-wrapper">

          </div>
        </div>

            </>
          );
  }
}

export default YourComponent;
