import React from 'react';
import './Styles/media.css';
import MediaOne from './Images/media-one.png';
import MediaTwo from './Images/media-two.png';
import MediaThree from './Images/media-three.png';
import MediaFour from './Images/media-four.png';
import Rating from './Images/star-ratings.png';

const Media = () => (
  <div className="media-wrapper" style={{    borderBottom: '10px solid #f8f8f8' }}>
    <div className="media-section">
      <h2>Du bist richtig bei uns!
          Wir NETZWERKEN und „MATCHEN” Dich.</h2>
      <hr
        style={{
          backgroundColor: '#355fb3',
          width: '240px',
          height: '2px',
          border: 'none',
          marginTop: '15px'
        }}
      />
        <br />
        <br />

    </div>
  </div>
);

export default Media;
