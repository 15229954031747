import React, { useState, Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import Autocomplete from '../Autocomplete';
import Background from './Background';
import Logo from '../Images/pmp.png';
import '../Styles/Header.css';
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";
import { Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown,Divider, message, Space, Tooltip } from 'antd';
import { elastic as Menu } from 'react-burger-menu'

const navigationDataC1 = [
   {
            id: 0,
            name: 'Suchen',
            url: ''
          },
        {
          id: 1,
          name: 'Veranstaltungen',
           url: 'veranstaltungen'
        },
        {
          id: 2,
          name: 'Referenten',
         url: 'referenten'
        },
        {
          id: 3,
          name: 'Hersteller/ Produkte',
         url: 'hersteller'
        }
];
const navigationDataC2 = [
        {
          id: 4,
          name: 'Versorgungspartner',
         url: 'versorgungspartner'
        },
     {
       id: 5,
       name: 'Über PMP.online',
      url: 'info'
     }
];



function scrollToTargetAdjusted(){
    var element = document.getElementById('go-filter');
    var headerOffset = document.getElementsByClassName("first-line")[0].clientHeight + document.getElementsByClassName("second-line")[0].clientHeight + 14;
//    ("first-line").clientHeight + document.getElementById("second-line").clientHeight;

    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

class Header extends Component {
  constructor() {
    super()
    this.state = {
        selectedOption: { label: "Veranstaltung", value: "Veranstaltung", route: "/veranstaltungen" },
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        experience: 'Suchen',
        searchText: ''
      };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changeSearchText = event => this.setState({ searchText: event.target.value });

  changeExperience = experience => this.setState({ experience: experience });

  handleChange = selectedOption => {
      this.setState({ selectedOption: selectedOption });
  //    this.changeUrl(selectedOption.value);
    };

  render() {
    const {
      backgroundImagesData,
      navigationData,
      selectedCity,
      veranstaltungenData,
      history,
      setFilterAdvancedHighlighted,
      filterAdvancedHighlighted
    } = this.props;

  const atHome = (this.props.history.location.pathname === "/");
  const atVeranstaltungen = (this.props.history.location.pathname === "/veranstaltungen");
  const atVersorgungspartner = (this.props.history.location.pathname === "/versorgungspartner");
  const atReferenten = (this.props.history.location.pathname === "/referenten");
  const atHersteller = (this.props.history.location.pathname === "/hersteller");


    const { selectedOption } = this.state;

    const isLeaf = history.location.pathname.match(/\/veranstaltungen\/(\d+)/) || history.location.pathname.match(/\/referenten\/(\d+)/) || history.location.pathname.match(/\/produkt\/(\d+)/) || history.location.pathname.match(/\/hersteller\/(\d+)/);

    return (
      <React.Fragment>
        <HeaderNav
        handleChange={this.handleChange}
          veranstaltungenData={veranstaltungenData}
          changeSearchText={this.changeSearchText}
          experience={this.state.experience}
          changeExperience={this.changeExperience}
          searchText={this.state.searchText}
          filterAdvancedHighlighted={this.state.filterAdvancedHighlighted}
          history={history}
          pathname={history.location.pathname}
          selectedCity={selectedCity}
          navigationData={navigationData}
        />
        <Background veranstaltungenData={veranstaltungenData} history={this.props.history} backgroundImagesData={backgroundImagesData} />
        { (atHome || atVeranstaltungen || atReferenten || atVersorgungspartner || atHersteller) && <div style={{ marginTop: (atHersteller || atVersorgungspartner)  ? 74 : -42, marginBottom: atHersteller ? -42 : 'inherit'  }} className="search-bar-div">
          <div className={"select-city-large " + (atHome ? "exl" : "")}>
            <i className="fas fa-map-marker" />
            <Searchbar
              handleChange={this.handleChange}
              selectedOption={selectedOption.value}
              style={customStyles}
              history={this.props.history}
              selectedCity={this.props.selectedCity}
            />
          </div>
          <div className="select-experience-large">
          { !atHome && <Autocomplete
              history={this.props.history}
              onChange={this.changeSearchText}
              value={this.state.searchText}
              suggestions={veranstaltungenData.filter(v => v.type === (atVeranstaltungen ? 'Veranstaltung' : atHersteller ? 'Produkt' : atVersorgungspartner ? 'Versorgungspartner' : atReferenten ? 'Referent' : selectedOption.value))} />}

          </div>
          { (atReferenten || atVersorgungspartner || atVeranstaltungen || atHersteller) && <button onClick={() => {
          if (!filterAdvancedHighlighted) scrollToTargetAdjusted();
            setFilterAdvancedHighlighted(!filterAdvancedHighlighted) }} id="go-filter">{ filterAdvancedHighlighted ? "Erweiterte Filter verstecken" : "Erweiterte Filter anzeigen"}</button> }
          { atHome && <button onClick={() => {
            this.props.history.push(`${selectedOption.route}`) }} id="go">Los!</button> }
        </div>
        }
      </React.Fragment>
    );
  }
}

const SioAvatar = (props) => {
    const { user } = useAuthContext();
    return (
            <Tooltip placement="bottom" title={ user ? 'Benutzerkonto' : 'Anmelden'}>
                <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#355fb3') }} icon={<UserOutlined />} />
            </Tooltip>
    );
}
/*
    return (<>
    user ? (<Dropdown menu={{
  onClick: props.handleMenuClick,
  items: [
    {
        label: 'Benutzerkonto',
        key: '1',
      },
    {
        label: 'Abmelden',
        key: '2',
        danger: true,
      }
    ]}}>
          <Button>
            <Space>
              <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#355fb3') }} icon={<UserOutlined />} />
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>) : (<Tooltip placement="bottom" title={ user ? 'Benutzerkonto' : 'Anmelden'}>
            <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#355fb3') }} icon={<UserOutlined />} />
        </Tooltip>)
        </>);
        */
export class HeaderNav extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      adminActive: false
    }
  }

  componentDidMount() {
    // Access history after the component has mounted
    const { pathname } = this.props;
      this.setState({
        adminActive: pathname.indexOf("/admin") > -1
      });
  }

  componentDidUpdate(prevProps) {
      const { pathname } = this.props;
      if (prevProps.pathname !== pathname) {
        this.setState({
          adminActive: pathname.indexOf("/admin") > -1
        });
      }
  }

  static defaultProps = {
    navigationData: [
  {
          id: 0,
          name: 'Suchen',
          url: ''
        },
      {
        id: 1,
        name: 'Veranstaltungen',
         url: 'veranstaltungen'
      },
      {
        id: 2,
        name: 'Referenten',
       url: 'referenten'
      },
      {
        id: 3,
        name: 'Hersteller/Produkte',
       url: 'hersteller'
      }
    ],
    editorNavigationData: [
  {
          id: 4,
          name: 'Inserieren',
          url: 'admin/inserieren'
        },
      {
        id: 5,
        name: 'Meine Inserate',
         url: 'admin/meine-inserate',
      },
      {
        id: 6,
        name: 'Benutzerkonto',
       url: 'admin/benutzerkonto'
      }
    ]
  };


handleMenuClick = (e) => {
    if (e.key === '1') {
        this.props.history.push(`/admin/benutzerkonto`);
    } else if (e.key === '2') {
        this.handleLogout();
    }
};

  handleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeSideBar = () => {
    this.setState({ isOpen: false });
  }

  handleLogout = () => {
    removeToken();
    this.props.history.push("/signin", { replace: true });
  };

  render() {
    const {   handleChange, navigationData, editorNavigationData, experience, searchText, filterAdvancedHighlighted } = this.props;
    const { adminActive, isOpen } = this.state;
    const screenWidth = document.getElementById("root").clientWidth;
    const isMobile = screenWidth < 1194;

    const navFirstRow = screenWidth > 1194 ? navigationData : navigationDataC1;
    return (<>
    { isMobile && <Menu
        isOpen={isOpen}
        onOpen={this.handleIsOpen}
        onClose={this.handleIsOpen}
    right={true}>
                    {navigationData &&
                                        navigationData.map(({ id, name, url }, i) => (
                                            <Link
                                              onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                              to={{ pathname: `/${url}` }}
                                              key={"l--" + id}
                                              className="link"
                                            >
                                                <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                  <span className="label"> {name}</span>
                                                </li>
                                            </Link>
                                        ))}
                    {  adminActive && (editorNavigationData &&
                              <>
                              <Divider />

                              {editorNavigationData.map(({ id, name, url }, i) => (
                                 <Link
                                   onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                   to={{ pathname: `/${url}` }}
                                   key={"l--" + id}
                                   className="link"
                                 >
                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                           <span className="label"> {name}</span>
                                                         </li>
                                 </Link>
                             ))}
                             </>
                             ) }
                             { !adminActive &&  <>
                             <Divider />
                             <Link
                                   onClick={() => { this.props.changeExperience('signin'); this.closeSideBar(); }}
                                   to={{ pathname: `/signin` }}
                                   key={"l--00" }
                                   className="link"
                                 >
                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf('sign') > -1) ? " iwnav-current" : "")} key={102}>
                                                            <span className="label"> Anmelden</span>
                                                         </li>

                                </Link></>}

                  </Menu> }
      <div className="header-wrap">
        <div style={{ height: isMobile ? 92 : 134 }} className="header-wrapper extended navbar-fixed-top">
          <div className="header-left">
            <div className="nav">
              <div className="first-line">
               <div className="second-line-wrapper">
                <Link className="logo_a" to={{ pathname: `/` }}>
                  <div className="logoWrapper">
                    <img id="logo" src={Logo} alt="PMP.online" />
                  </div>
                  <div className="logoLabels">
                    <span className="logoMain">PMP.online</span><br/>
                    <span className="logoSubtitle">Portal für Medizin und Pflege</span>
                  </div>
                </Link>
                <div id="topSearch" style={{ opacity: 0 }} className="search-line-wrapper">
                <div className="select-city">

                </div>
                <div className="select-experience">
                  <input
                    type="text"
                    placeholder="Suchen"
                    onChange={this.changeSearchText}
                    value={this.props.searchText}
                  />
                  <i className="fas fa-search" />
                </div>
               </div>
               </div>
              </div>
              <div style={{ display: isMobile ? 'none' : 'inherit', marginBottom: isMobile ? '-48px' : 'inherit' }} className="second-line">
               <div className="second-line-wrapper">
                <div className="best-picked">
                    <ul className="iwnav-channels">
                  {navFirstRow &&
                    navFirstRow.map(({ id, name, url }, i) => (
                        <Link
                          onClick={() => { this.props.changeExperience(name); }}
                          to={{ pathname: `/${url}` }}
                          key={"l--" + id}
                          className="link"
                        >
                            <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                              <span className="label"> {name}</span>
                            </li>
                        </Link>
                    ))}
                    </ul>
                </div>

                { screenWidth > 948 && <div className="support">
                                   <div className={"i-button"} onClick={ () => this.props.history.push(`/admin/inserieren`) }>Inserieren</div>
                  <p>
                   <a
                                     onClick={ () => this.props.history.push(`/signin`) }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <div className="sign-in">
                           <SioAvatar handleMenuClick={this.handleMenuClick} />
                      </div>
                    </a>
                    </p>
                </div> }
               </div>
               <div className={"second-line adminNav" + (adminActive ? " adminNav--active" : "")}>
                                  <div className="second-line-wrapper">
                                       <div className="best-picked">
                                           <ul className="iwnav-channels">
                                         { screenWidth < 948 ? (navigationDataC2 &&
                                             navigationDataC2.map(({ id, name, url }, i) => (
                                               <Link
                                                 onClick={() => { this.props.changeExperience(name); }}
                                                 to={{ pathname: `/${url}` }}
                                                 key={"l--" + id}
                                                 className="link"
                                               >
                                                 <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                         <span className="label"> {name}</span>
                                                                       </li>
                                               </Link>
                                           ))) : (editorNavigationData &&
                                                 editorNavigationData.map(({ id, name, url }, i) => (
                                                    <Link
                                                      onClick={() => { this.props.changeExperience(name); }}
                                                      to={{ pathname: `/${url}` }}
                                                      key={"l--" + id}
                                                      className="link"
                                                    >
                                                      <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                              <span className="label"> {name}</span>
                                                                            </li>
                                                    </Link>
                                                ))) }
                                           </ul>
                                       </div>
                                   </div>
                               </div>
               { screenWidth < 948 && <div className="second-line">
                 <div className="second-line-wrapper">
                  <div className="support support--second" style={{ marginLeft: screenWidth < 948 ? 0 : '12%'}}>
                  <ul className="iwnav-channels">
                                                           {editorNavigationData &&
                                                             editorNavigationData.map(({ id, name, url }, i) => (
                                                                 <Link
                                                                   onClick={() => { this.props.changeExperience(name); }}
                                                                   to={{ pathname: `/${url}` }}
                                                                   key={"l--" + id}
                                                                   className="link"
                                                                 >
                                                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                                           <span className="label"> {name}</span>
                                                                                         </li>
                                                                 </Link>
                                                             ))}
                                                             </ul>
                                                     <div className={"i-button"} onClick={ () => this.props.history.push(`/admin/inserieren`) }>Inserieren</div>
                                    <p>
                                        <a
                                            onClick={ () => this.props.history.push(`/signin`) }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ textDecoration: 'none' }}
                                            >
                                              <div className="sign-in">
                                                   <SioAvatar handleMenuClick={this.handleMenuClick} />
                                              </div>
                                        </a>
                                      </p>
                                  </div>
                  </div>
                  </div>
                   }
              </div>
            </div>

          </div>
        </div>
      </div>
      </>
    );
  }
}

const options = [
  { value: 'Veranstaltung', label: 'Veranstaltung', route: '/veranstaltungen' },
  { value: 'Referent', label: 'Referent', route: '/referenten'  },
  { value: 'Hersteller', label: 'Hersteller', route: '/hersteller'  },
  { value: 'Produkt', label: 'Produkte', route: '/hersteller'  },
  { value: 'Versorgungspartner', label: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const optionsHome = [
  { label: 'Ich suche nach Veranstaltungen', value: 'Veranstaltung', route: '/veranstaltungen' },
  { label: 'Ich suche nach Referenten', value: 'Referent', route: '/referenten'  },
  { label: 'Ich suche nach Herstellern', value: 'Hersteller', route: '/hersteller#HerstellerSection'  },
  { label: 'Ich suche nach Produkte', value: 'Produkte', route: '/hersteller'  },
  { label: 'Ich suche nach Versorgungspartner', value: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '13px',
    textAlign: 'left',
    width: 120
  }),
  control: () => ({
    width: 150,
    display: 'flex',
    fontSize: '14px',
    marginTop: '10px',
    paddingLeft: '5px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const smallSearchbar = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '15px',
    textAlign: 'left',
    paddingLeft: '20px',
    width: 150
  }),
  control: () => ({
    width: 160,
    display: 'flex',
    fontSize: '14px',
    marginLeft: '15px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

class Searchbar extends Component {

  changeUrl = url => {
    this.props.history.push(`/veranstaltungen/${url}`);
  };

  render() {
    const { history,selectedCity, selectedOption, handleChange } = this.props;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 8,
    marginLeft: 14,
    width: '300px', // Set the width here
  }),
};

  const atHome = (this.props.history.location.pathname === "/");
  const atVeranstaltungen = (this.props.history.location.pathname === "/veranstaltungen");
  const atVersorgungspartner = (this.props.history.location.pathname === "/versorgungspartner");
  const atReferenten = (this.props.history.location.pathname === "/referenten");
  const atHersteller = (this.props.history.location.pathname === "/hersteller");

    if (selectedCity) {
      return (
        <Select
          styles={this.props.style}
          placeholder={selectedCity}
          value={selectedOption}
          onChange={handleChange}
          options={options}
          className="city-select-dropdown"
        />
      );
    } else {
      return (<>
        { atHome ? <div className={"fullWidthSelect"} ><Select
           styles={customStyles}
          value={optionsHome.find(o => o.value === selectedOption)}
          onChange={handleChange}
          options={optionsHome}
          className="city-select-dropdown city-select-dropdown-home--new"
        /></ div> : atVeranstaltungen ? <Select
                                           styles={this.props.style}
                                           value={{label: "Veranstaltung", value: "Veranstaltung"}}
                                           onChange={handleChange}
                                           options={options}
                                           className="city-select-dropdown veranstaltungen"
                                         /> : atReferenten
                                          ? <Select styles={this.props.style}
                                                                value={{label: "Referenten", value: "Referenten"}}
                                                                onChange={handleChange}
                                                                options={options}
                                                                className="city-select-dropdown veranstaltungen"
                                                              />  : atHersteller
                                                                                                            ? <Select styles={this.props.style}
                                                                                                                                  value={{label: "Produkte", value: "Produkte"}}
                                                                                                                                  onChange={handleChange}
                                                                                                                                  options={options}
                                                                                                                                  className="city-select-dropdown veranstaltungen"
                                                                                                                                />  : atVersorgungspartner
                                                                                                            ? <Select styles={this.props.style}
                                                                                                                                  value={{label: "Versorgungspartner", value: "Versorgungspartner"}}
                                                                                                                                  onChange={handleChange}
                                                                                                                                  options={options}
                                                                                                                                  className="city-select-dropdown veranstaltungen versorgungspartner"
                                                                                                                                /> : null
                                          }
        </>
      );
    }
  }
}

export default withRouter(Header);