import { AUTH_TOKEN, EXPIRE_DATE, USER_E } from "./constant";

export const getToken = () => {
  return  localStorage.getItem(AUTH_TOKEN);//  "dummyToken"; // localStorage.getItem("dummyToken"); // localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export function calculateDistance(l1, l2) {
    const [lat1, lon1] = l1;
    const [lat2, lon2] = l2;

    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2-lat1) * Math.PI / 180;
    const Δλ = (lon2-lon1) * Math.PI / 180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = R * c; // Distance in meters
    return d;
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const getExpire = () => {
  return  localStorage.getItem(EXPIRE_DATE);//  "dummyToken"; // localStorage.getItem("dummyToken"); // localStorage.getItem(AUTH_TOKEN);
};

export const setExpire = (expire) => {
  if (expire) {
    localStorage.setItem(EXPIRE_DATE, expire);
  }
};

export const removeExpire = () => {
  localStorage.removeItem(EXPIRE_DATE);
};


export const getUserE = () => {
  return  localStorage.getItem(USER_E);
};

export const setUserE = (expire) => {
  if (expire) {
    localStorage.setItem(USER_E, expire);
  }
};

export const removeUserE = () => {
  localStorage.removeItem(USER_E);
};
